import React, { Fragment } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import ListItemButton from "@mui/material/ListItemButton";
import { MdCircle } from "react-icons/md";

function MenuDropdownButton({
  openCollapse,
  submenu,
  handleOpenSettings,
  handleClick,
  item,
  open,
  openD,
}) {
  const navigate = useNavigate();
  const navigateTo = (link) => {
    navigate(link);
  };

  const location = useLocation();

  return (
    <>
      <List>
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
          }}
          // onClick={handleOpenSettings} key={item.title}
          onClick={() => handleClick(item.id)}
          key={item.id}
          className={openD[item.id] ? "isActiveDropdown" : ""}
        >
          {item.icon && (
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              {item.icon}
            </ListItemIcon>
          )}

          {open ? (
            <ListItemText
              primary={item.title}
              sx={{ opacity: open ? 1 : 0, fontWeight: "bold" }}
            />
          ) : null}
          {open && (
            <Fragment>
              {openD[item.id] ? (
                <ExpandLess sx={{ color: "white" }} />
              ) : (
                <ExpandMore sx={{ color: "white" }} />
              )}
            </Fragment>
          )}
        </ListItemButton>

        <Collapse in={openD[item.id]} timeout="auto" unmountOnExit>
          {submenu.map((submenu) => (
            <List component="div" disablePadding key={submenu.title}>
              <ListItemButton
                onClick={() => navigateTo(submenu.route)}
                className={
                  submenu.route === `${location.pathname}`
                    ? "isActiveSubDropdown"
                    : ""
                }
                sx={{ pl: 4 }}
              >
                <ListItemIcon>
                  <MdCircle size={10} />
                </ListItemIcon>
                <ListItemText>{submenu.title}</ListItemText>
              </ListItemButton>
            </List>
          ))}
        </Collapse>
      </List>
    </>
  );
}

export default MenuDropdownButton;
