import React, { useState } from "react";
import { Box, Typography, Dialog, DialogContent } from "@mui/material";

import Colors from "../../utils/Colors";
import { useSelector } from "react-redux";
import VgButton from "../general/VgButton";

import { MdClose } from "react-icons/md";
import { styles } from "../../../assets/styles/modalStyles";
import { useNavigate } from "react-router-dom";

const SCREEN_WIDTH = Math.round(window.innerWidth);
const SCREEN_HEIGHT = Math.round(window.innerHeight);

const ActiveModal = ({ isDialogOpen, setDialogOpen, status }) => {
  const { modals } = useSelector((state) => state.user.language);
  const { supportPhone } = useSelector((state) => state.items.supportPhone);
  const navigate = useNavigate();

  const handleWhatsAppClick = () => {
    const phoneNumber = supportPhone ? supportPhone : "50686187854";
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}`;
    window.open(whatsappUrl, "_blank");
  };
  const toggleDialog = () => {
    setDialogOpen(!isDialogOpen);
  };

  const contactSupport = () => {
    handleWhatsAppClick();
  };
  const navigateTo = (route) => {
    navigate(route);
  };
  return (
    <Dialog
      open={isDialogOpen}
      onClose={() => navigateTo("/home")}
      fullWidth
      maxWidth={"xs"}
      PaperProps={{
        sx: {
          borderTopLeftRadius: "32px",
          borderBottomLeftRadius: "32px",
          background: "transparent",
          minHeight: "85vh",
          //maxWidth: { lg: "30%", md: "40%", sm: "60%", xs: "85%" },
          minWidth: { xl: "15%", lg: "21%", md: "25%", sm: "35%", xs: "40%" },
          //width: { lg: "30%", md: "40%", sm: "60%", xs: "85%" },
        },
      }}
    >
      <DialogContent sx={styles.modalBackground}>
        <Box sx={{ position: "absolute", top: 15, right: 25 }}>
          <MdClose
            size={32}
            color={Colors.gold}
            style={{ cursor: "pointer" }}
            onClick={() => navigateTo("/home")}
          />
        </Box>
        <Box sx={styles.modalCard}>
          {status === "Inactivo" ? (
            <Box
              component={"img"}
              style={{
                // width: 150,
                // height: 100,
                alignSelf: "center",
                objectFit: "contain",
              }}
              src={require("../../../assets/img/lock.png")}
            />
          ) : status === "Activo" ? (
            <Box
              component={"img"}
              style={{
                // width: 150,
                // height: 100,
                alignSelf: "center",
                objectFit: "contain",
              }}
              src={require("../../../assets/img/crown.png")}
            />
          ) : (
            <Box
              component={"img"}
              style={{
                // width: 150,
                // height: 100,
                alignSelf: "center",
                objectFit: "contain",
              }}
              src={require("../../../assets/img/lock.png")}
            />
          )}
          <Typography
            variant="body1"
            sx={{
              fontSize: 15,
              color: "white",
              textAlign: "center",
              alignSelf: "center",
              fontFamily: "Poppins, sans-serif",
              margin: 5,
            }}
          >
            {status === "Inactivo"
              ? modals.activeModal.p1
              : status === "Activo"
              ? modals.goldModal.p1
              : modals.activeModal.p1}

            <span style={{ color: Colors.gold, fontSize: 16 }}>
              {modals.activeModal.p1_color_word}
            </span>
          </Typography>
        </Box>
        <Box style={styles.logoContainer}>
          <Box
            component={"img"}
            style={styles.logo}
            resizeMode="contain"
            src={require("../../../assets/img/V1.png")}
          />
        </Box>
        <Typography
          variant="body1"
          sx={{
            color: "common.white",
            fontSize: 15,
            alignSelf: "center",
            fontFamily: "Poppins, sans-serif",
            textAlign: "center",

            marginTop: 0,
          }}
        >
          {modals.activeModal.p2}
        </Typography>
        <Box
          style={{
            alignItems: "center",
            marginTop: 30,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <VgButton
            fontSize={16}
            title={modals.goldModal.btn}
            onPress={contactSupport}
            width={200}
            height={40}
            color={Colors.text2}
            bgColor={Colors.light}
            bgColor2={Colors.grey2}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ActiveModal;
