import { Box, Button, Grid } from "@mui/material";
import React from "react";
import Colors from "../../utils/Colors";
import { useNavigate } from "react-router-dom";
import VgButton from "../../components/general/VgButton";
import img1 from "../../../assets/img/09-sintectic-power-lab.png";
import img2 from "../../../assets/img/10-pro-academy.png";

export const SyntheticCategoryView = () => {
  const navigate = useNavigate();

  const navigateToPlaylist = (category) => {
    navigate(`/trading/playlist/${category}`);
  };
  const categories = [
    {
      id: "1",
      text: "SINTHETIC POWER LAB",
      route: "lab",
      imgURL: img1,
    },
    {
      id: "1",
      text: "PRO ACADEMY",
      route: "pro",
      imgURL: img2,
    },
  ];
  return (
    <Grid
      container
      justifyContent={"center"}
      alignItems={"center"}
      sx={{ minHeight: "75vh" }}
    >
      {categories.map((item) => {
        return (
          <Grid
            item
            container
            md={6}
            key={item.id}
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"column"}
          >
            <Box>
              <img
                src={item.imgURL}
                style={{ height: 425, width: "auto" }}
                alt="Image 2"
              />
            </Box>
            <Box sx={{ mt: 0 }}>
              <VgButton
                onPress={() => navigateToPlaylist(item.route)}
                shadow
                bgColor={Colors.yellowAcademy}
                bgColorB={Colors.yellowDark2}
                title={item.text}
                width={275}
                height={45}
              />
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};
