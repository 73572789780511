import { auth, db, storage } from "../../../firebase/firebase-config";
import { ADMIN_API_URL } from "../../variables/globals";
import { types } from "../types/types";
import { firestoreRT } from "./user";

export const refreshToken = async () => {
  const token = await auth.currentUser.getIdToken(true);

  return token;
};

export const login = (email, password, setloading) => {
  return async (dispatch) => {
    try {
      const response = await auth.signInWithEmailAndPassword(email, password);
      const idToken = await response.user.getIdToken(true);
      const metadata = await response.user.getIdTokenResult();

      dispatch(signInWithEmail(response.user.toJSON(), true, idToken));
      dispatch(setAuthError(""));
    } catch (error) {
      dispatch(setAuthError(error.code));
      setloading(false);
      console.error(error);
    }
  };
};
export const updateAuthUserPhotoURL = (photoURL) => {
  const user = { photoURL };
  return auth.currentUser.updateProfile(user);
};

export const updateAuthUserInfo = (name) => {
  const user = { displayName: name };
  return auth.currentUser.updateProfile(user);
};

export const uploadPhoto = async (uid, photoFromDevice) => {
  const storageRef = storage.ref();

  try {
    const ref = storageRef.child(`/users/${uid}/profile`);

    console.log("Uploading file:", photoFromDevice);

    const snapshot = await ref.put(photoFromDevice); // Use put instead of putFile

    console.log("Upload result:", snapshot);

    if (snapshot.state === "success") {
      const imageURL = await ref.getDownloadURL();

      console.log("Image URL:", imageURL);

      await updateAuthUserPhotoURL(imageURL);

      console.log("Auth user photo URL updated.");

      await firestoreRT()
        .doc(`users/${uid}`)
        .set({ photoURL: imageURL }, { merge: true });

      console.log("Firestore user photo URL updated.");
    }
  } catch (err) {
    console.error("Error uploading photo:", err);
  }
};

export const logoutUser = () => {
  return async (dispatch) => {
    try {
      await auth.signOut();
      dispatch(logout());
    } catch (error) {
      console.error(error);
    }
  };
};
export const signInWithEmail = (user, claims, idToken) => ({
  type: types.login,
  payload: {
    user,
    loggedIn: true,
    // isAlmighty: claims.almighty,
    // isSuperAdmin: claims.superadmin,
    // isAdmin: claims.admin,
    // isModerator: claims.moderator,
    idToken: idToken,
  },
});

export const setAuthError = (errorCode) => {
  let errorMsg = "";

  switch (errorCode) {
    case "auth/user-not-found":
      errorMsg = "Usuario no encontrado. Verifique sus credenciales.";
      break;
    case "auth/wrong-password":
      errorMsg = "Contraseña incorrecta. Verifique sus credenciales.";
      break;
    // Add more error codes and corresponding messages as needed
    default:
      errorMsg = "Error en el inicio de sesión. Verifique sus credenciales.";
  }

  return {
    type: types.errorMsg,
    payload: {
      errorCode: errorCode,
      errorMsg: errorMsg,
    },
  };
};
export const handleResetPassword = async (email) => {
  try {
    await auth.sendPasswordResetEmail(email);
    return null; // No error message on success
  } catch (error) {
    console.error("Error sending reset email:", error.message);
    // Handle error (return specific error message to the caller)
    let errorMessage =
      "Hubo un error al enviar el correo electrónico de restablecimiento. Por favor, inténtalo de nuevo.";

    if (error.code === "auth/user-not-found") {
      errorMessage =
        "No se encontró ningún usuario con esa dirección de correo electrónico. Es posible que el usuario haya sido eliminado. Por favor, verifica la dirección de correo electrónico e inténtalo de nuevo.";
    }

    // You can add more specific error handling based on other error codes if needed.

    return errorMessage;
  }
};

export const setIsLoadingAuth = (isLoading) => ({
  type: types.isLoadingAuth,
  payload: {
    isLoadingAuth: isLoading,
  },
});

export const logout = () => ({
  type: types.logout,
  payload: {},
});

export const ReCaptcha = (token) => {
  // https://www.google.com/recaptcha/api/siteverify
};
