import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  TextField,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { MdEdit } from "react-icons/md";
import Colors from "../../utils/Colors";
import { useDispatch, useSelector } from "react-redux";

import { uploadPhoto } from "../../store/actions/auth";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import VgButton from "../../components/general/VgButton";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {
  firestoreRT,
  setUserDataRT,
  updateUser,
} from "../../store/actions/user";
import { styles } from "../../../assets/styles/styles";
import { fsTimestampCreador } from "../../../firebase/firebase-config";
import { ForgotPassword } from "../../components/auth/ForgotPassword";

const MySwal = withReactContent(Swal);
export const ProfileView = () => {
  const theme = useTheme();
  const USERDATA = useSelector((state) => state.user.userData);
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [loading, setloading] = useState(true);
  const [previewImage, setPreviewImage] = useState(user?.photoURL);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [parsedDate, setParsedDate] = useState(dayjs("2005-01-01"));
  const [openDialog, setOpenDialog] = useState(false);
  const formatDate = (date) =>
    !date.seconds
      ? date // Already a javascript date object
      : date.toDate();

  useEffect(() => {
    if (user) {
      const snap = firestoreRT()
        .collection("users")
        .doc(user.uid)
        .onSnapshot(
          async (userRT) => {
            dispatch(setUserDataRT(userRT.data()));
          },
          (error) => console.log(error),
          (userRTC) => console.log(userRTC)
        );

      return snap;
    }
  }, [user]);
  useEffect(() => {
    if (user?.photoURL) {
      setPreviewImage(user?.photoURL);
    } else {
      setPreviewImage("https://picsum.photos/200/200");
    }
  }, [previewImage]);

  useEffect(() => {
    if (USERDATA?.birthday) {
      const birthdayDayjs = formatDate(USERDATA.birthday);
      const parsed = dayjs(birthdayDayjs);
      setParsedDate(parsed);
      console.log([parsed]);
    }
  }, [USERDATA]);

  const initialValues = {
    email: USERDATA.email ?? "",
    name: USERDATA.name ?? "",
    lastname: USERDATA.lastname ?? "",
    phone: USERDATA.phone ?? "",
    country: USERDATA.country ?? "",
    city: USERDATA.city ?? "",
    address: USERDATA.address ?? "",
    birthday: parsedDate,
    idType: USERDATA.idType ?? "",
    personalId: USERDATA.personalId ?? "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    // Add validation rules for other fields
  });

  useEffect(() => {
    console.log(isEditing);
  }, [isEditing]);

  const onSubmit = (values) => {
    // Handle form submission here
    submitForm(values);
    // console.log("Form values:", values);
  };

  const submitForm = async (values) => {
    const timestampBirthday = fsTimestampCreador.fromDate(
      new Date(formik.values.birthday)
    );

    const data = {
      name: values.name || "",
      lastname: values.lastname || "",
      phone: values.phone || "",
      country: values.country || "",
      city: values.city || "",
      address: values.address || "",
      birthday: timestampBirthday,
      // birthday: dateOfBirthAsDate,
      idType: values.idType || "",
      personalId: values.personalId || "",
    };

    const result = await MySwal.fire({
      title: "¿Confirmar?",
      text: "¿Está seguro que quiere editar su perfil",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Editar",
      cancelButtonText: "Cancelar",
    });

    if (result.isConfirmed) {
      // Show loading indicator
      const loadingSwal = Swal.fire({
        title: "Guardando...",

        showConfirmButton: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      try {
        await updateUser(user.uid, data);
        MySwal.fire("Guardado!", "", "success");
        loadingSwal.close();
      } catch (error) {
        MySwal.fire("Hubo un error al guardar el item", "", "error");
        console.log("edit error", error);
        loadingSwal.close();
      }
    } else if (result.isDenied) {
      loadingSwal.close();
      MySwal.fire("Cambios no guardados", "", "info");
    }
  };

  const handleChangePassword = async () => {
    const result = await MySwal.fire({
      title: "Cambiar Contraseña",
      text: "¿Estás seguro que quieres cambiar tu contraseña?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      reverseButtons: true,
    });

    if (result.isConfirmed) {
      // User clicked "Sí", proceed with changing the password
      try {
        // Implement logic to change the user's password
        // Example: await changeUserPassword(user.uid, newPassword);
        console.log("Password changed successfully");

        // Show success message
        MySwal.fire(
          "Contraseña Cambiada",
          "Tu contraseña ha sido cambiada con éxito.",
          "success"
        );
      } catch (error) {
        console.error("Error changing user password:", error);

        // Show error message
        MySwal.fire(
          "Error",
          "Hubo un error al cambiar la contraseña. Por favor, inténtelo de nuevo.",
          "error"
        );
      }
    } else if (result.isDismissed) {
      // User clicked "No" or outside the dialog
      MySwal.fire("Cambios no realizados", "", "info");
    }
  };

  const removeAll = () => {
    setImages([]);
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });
  const handleImageChange = (event) => {
    const file = event.target.files[0];

    // Check if a file is selected
    if (file) {
      try {
        // Update the previewImage to show the selected image
        setPreviewImage(URL.createObjectURL(file));
        setSelectedImage(file);
        setIsEditing(true);
      } catch (error) {
        console.error("Error creating object URL:", error);
      }
    }
  };
  const handleSave = async () => {
    try {
      // Show a confirmation dialog using Swal
      const result = await Swal.fire({
        title: "Confirmar",
        text: "¿Desea guardar los cambios?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Guardar",
        cancelButtonText: "Cancelar",
        reverseButtons: true,
      });

      // If the user clicks "Guardar," proceed with the save process
      if (result.isConfirmed) {
        const loadingSwal = Swal.fire({
          title: "Guardando...",

          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        if (selectedImage) {
          await uploadPhoto(user.uid, selectedImage);
        }
        // Additional logic can be added here

        // Reset the selectedImage and update the previewImage back to the user's photoURL
        setSelectedImage(null);
        setPreviewImage(USERDATA.photoURL);
        setIsEditing(false); // Close the file select

        loadingSwal.close();
        Swal.fire(
          "Guardado",
          "Los cambios han sido guardados con éxito.",
          "success"
        );
      }
    } catch (error) {
      console.error("Error updating user profile:", error);

      // Show an error message using Swal
      Swal.fire(
        "Error",
        "Hubo un error al guardar los cambios. Por favor, inténtelo de nuevo.",
        "error"
      );
    }
  };

  const handleEdit = () => {
    setIsEditing(true); // Open the file select
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    if (USERDATA) {
      formik.setValues({
        email: USERDATA.email ?? "",
        name: USERDATA.name ?? "",
        lastname: USERDATA.lastname ?? "",
        phone: USERDATA.phone ?? "",
        country: USERDATA.country ?? "",
        city: USERDATA.city ?? "",
        address: USERDATA.address ?? "",
        birthday: parsedDate,
        idType: USERDATA.idType ?? "",
        personalId: USERDATA.personalId ?? "",
      });
    }
  }, [USERDATA]); // Run when USERDATA changes

  const textFieldStyle = {
    color: "black",
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#E3E3E3",
      borderRadius: "12px !important",
      boxShadow: "inset 0 0 5px rgba(0, 0, 0, 0.2)",
      "& fieldset": {
        borderColor: "black",
      },
      "&:hover fieldset": {
        borderColor: "black",
      },
      "&.Mui-focused fieldset": {
        borderColor: "black",
      },
      "&:disabled": {
        borderColor: Colors.grey,
        backgroundColor: Colors.greyLight,
        color: Colors.grey,
      },
    },
    "& .MuiInputBase-input": {
      color: "black",
    },
    "& .MuiFormLabel-root": {
      color: `${Colors.yellowDark} !important`,
      fontWeight: "800 !important",
      fontSize: "14px",
      borderBottomWidth: 0,
      transform: "translate(10px, -20px)",

      "&:disabled": {
        color: Colors.grey,
      },
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: `${Colors.yellowDark} !important`,
    },
    "& .MuiInputBase-input::placeholder": {
      color: "grey !important",
    },
  };

  const StyledTextField = styled(({ ...other }) => <TextField {...other} />)(
    ({}) => ({
      color: "black",
      "& .MuiOutlinedInput-root": {
        backgroundColor: "#ececec",
        borderRadius: 18,
        boxShadow: "inset 0 0 5px rgba(0, 0, 0, 0.2)",
        "& fieldset": {
          borderColor: "black",
        },
        "&:hover fieldset": {
          borderColor: "black",
        },
        "&.Mui-focused fieldset": {
          borderColor: "black",
        },
        "&:disabled": {
          borderColor: Colors.grey,
          backgroundColor: Colors.greyLight,
          color: Colors.grey,
        },
      },
      "& .MuiInputBase-input": {
        color: "black",
      },
      "& .MuiFormLabel-root": {
        color: Colors.dark,
        "&:disabled": {
          color: Colors.grey,
        },
      },
      "& .MuiInputLabel-outlined.Mui-focused": {
        color: "black",
      },
      "& .MuiInputBase-input::placeholder": {
        color: "gray",
      },
    })
  );

  return (
    <Box sx={styles.profileView}>
      <Container maxWidth={"lg"}>
        <Grid
          container
          mt={2}
          mb={5}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Grid
            item
            container
            md={6}
            flexDirection={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            columnGap={2}
          >
            <Box>
              <label htmlFor="profile-image-input">
                <Avatar
                  src={previewImage}
                  alt="User Avatar"
                  sx={{ width: 125, height: 125, borderRadius: "18px" }}
                />
                {/* {!isEditing && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      mt: 1,
                    }}
                  >
                    <IconButton
                      onClick={handleEdit}
                      sx={{ backgroundColor: Colors.bgLight, mr: 1 }}
                    >
                      <MdEdit />
                    </IconButton>
                    <Typography onClick={handleEdit} sx={{ cursor: "pointer" }}>
                      {"Editar Foto"}
                    </Typography>
                  </Box>
                )} */}
              </label>

              {/* <input
                type="file"
                id="profile-image-input"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleImageChange}
              />
              {isEditing && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <IconButton onClick={handleSave}>
                    <MdEdit />
                  </IconButton>
                  <Typography onClick={handleSave} sx={{ cursor: "pointer" }}>
                    {"Guardar"}
                  </Typography>
                </Box>
              )} */}
            </Box>
            {/* {selectedImage && (
            <Grid container mt={4}>
              <Grid item container md={12} justifyContent={"flex-end"}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSave}
                  disabled={!selectedImage}
                >
                  Guardar
                </Button>
              </Grid>
            </Grid>
          )} */}
            <Box>
              <Typography variant="subtitle1">{USERDATA.country}</Typography>
              <Typography variant="subtitle1">{formik.values.name}</Typography>
              <Typography variant="subtitle1">{formik.values.email}</Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={4} container justifyContent={"center"} mt={2}>
            <VgButton
              title={" Cambiar Contraseña"}
              onPress={() => setOpenDialog(true)}
              bgColor={Colors.yellow}
              bgColorB={Colors.yellowDark}
            />
          </Grid>
        </Grid>

        <form onSubmit={formik.handleSubmit} className="profile-form">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                placeholder="Email"
                label="Email"
                fullWidth
                variant="outlined"
                name="email"
                unselectable="on"
                value={formik.values.email}
                sx={textFieldStyle}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                variant="outlined"
                fullWidth
                id="name"
                name="name"
                placeholder="Nombre"
                label="Nombre"
                type="text"
                value={formik.values.name}
                sx={textFieldStyle}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                placeholder="Apellido"
                fullWidth
                variant="outlined"
                name="lastname"
                label="Apellido"
                onChange={formik.handleChange}
                value={formik.values.lastname}
                sx={textFieldStyle}
                error={
                  formik.touched.lastname && Boolean(formik.errors.lastname)
                }
                helperText={formik.touched.lastname && formik.errors.lastname}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                placeholder="Teléfono"
                fullWidth
                variant="outlined"
                name="phone"
                label="Teléfono"
                onChange={formik.handleChange}
                sx={textFieldStyle}
                value={formik.values.phone}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                placeholder="País"
                label="País"
                fullWidth
                variant="outlined"
                name="country"
                sx={textFieldStyle}
                onChange={formik.handleChange}
                value={formik.values.country}
                error={formik.touched.country && Boolean(formik.errors.country)}
                helperText={formik.touched.country && formik.errors.country}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DatePicker
                placeholder="Fecha de Nacimiento"
                label="Fecha de Nacimiento"
                value={parsedDate}
                id="birthday"
                name="birthday"
                sx={{
                  width: "100%",
                  mt: 0.7,
                  color: "black !important",
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: "#ececec !important",
                    borderRadius: "12px !important",
                    boxShadow: "inset 0 0 5px rgba(0, 0, 0, 0.2) !important",
                    "& fieldset": {
                      borderColor: "black !important",
                    },
                    "&:hover fieldset": {
                      borderColor: "black !important",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "black !important",
                    },
                    "&:disabled": {
                      borderColor: `${Colors.grey} !important`,
                      backgroundColor: `${Colors.greyLight} !important`,
                      color: `${Colors.grey} !important`,
                    },
                  },
                  "& .MuiIconButton-root": {
                    color: `${Colors.yellowDark} !important`,
                  },
                  "& .MuiInputBase-input": {
                    color: "black !important",
                  },
                  "& .MuiFormLabel-root": {
                    color: `${Colors.yellowDark} !important`,
                    fontWeight: "800 !important",
                    fontSize: "14px",
                    borderBottomWidth: 0,
                    transform: "translate(10px, -20px)",

                    "&:disabled": {
                      color: Colors.grey,
                    },
                  },
                  "& .MuiInputLabel-outlined.Mui-focused": {
                    color: "black !important",
                  },
                  "& .MuiInputBase-input::placeholder": {
                    color: "gray !important",
                  },
                }}
                onChange={(newValue) => {
                  formik.setFieldValue("birthday", newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    margin="dense"
                    {...params}
                    variant="outlined"
                    error={
                      formik.touched.birthday && Boolean(formik.errors.birthday)
                    }
                    helperText={
                      formik.touched.birthday && formik.errors.birthday
                    }
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                placeholder="Identificación"
                label="Identificación"
                fullWidth
                variant="outlined"
                name="personalId"
                sx={textFieldStyle}
                onChange={formik.handleChange}
                value={formik.values.personalId}
                error={
                  formik.touched.personalId && Boolean(formik.errors.personalId)
                }
                helperText={
                  formik.touched.personalId && formik.errors.personalId
                }
              />
            </Grid>
          </Grid>
          <Grid container mt={4}>
            <Grid
              item
              container
              md={12}
              justifyContent={{ md: "flex-end", sm: "center", xs: "center" }}
            >
              {/* <Button type="submit" variant="contained" color="primary">
                Guardar
              </Button> */}
              <VgButton
                title={"  Guardar"}
                onPress={() => formik.handleSubmit()}
                bgColor={Colors.yellow}
                bgColorB={Colors.yellowDark}
              />
            </Grid>
          </Grid>
        </form>
      </Container>
      <ForgotPassword
        handleClose={handleClose}
        openDialog={openDialog}
        email={USERDATA?.email} // Pass the email as a prop
      />
    </Box>
  );
};
