import React from "react";
import "./App.scss";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./app/routes/routes";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import lightTheme from "./assets/styles/lightTheme";
import createCache from "@emotion/cache";
import { CacheProvider, jsx, css } from "@emotion/react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { esES } from "@mui/x-date-pickers/locales";
// import function to register Swiper custom elements
import { register } from "swiper/element/bundle";
// register Swiper custom elements
register();
import darkTheme from "./assets/styles/darkTheme";
const cache = createCache({
  key: "css",
  prepend: true,
});
window.Buffer = window.Buffer || require("buffer").Buffer;
function App() {
  return (
    <>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        localeText={
          esES.components.MuiLocalizationProvider.defaultProps.localeText
        }
      >
        <CacheProvider value={cache}>
          <CssBaseline />
          <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <div className="App ">
              <BrowserRouter>
                <AppRoutes />
              </BrowserRouter>
            </div>
          </ThemeProvider>
        </CacheProvider>
      </LocalizationProvider>
    </>
  );
}

export default App;
