import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Grid, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import shoe from "../../../assets/img/04-woman-of-power.png";
import microphone from "../../../assets/img/05-podcast.png";
import brain from "../../../assets/img/03-mindset.png";

import { AcademyCarousel } from "../../components/academy/AcademyCarousel";
import { AcademyCategories } from "../../components/academy/AcademyCategories";

import { setAcademy, setTrading } from "../../store/actions/items";
import { styles } from "../../../assets/styles/styles";
import PreviewModal from "../../components/modals/PreviewModal";

export const AcademyView = ({}) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState(null);
  const { academyNews } = useSelector((state) => state.items);

  const isFirstUse = useRef(true);

  const [loading, setLoading] = useState(true);

  const [dataAvailable, setDataAvailable] = useState(true);

  const getItemsFromFB = useCallback(async () => {
    try {
      setLoading(true);

      await dispatch(setAcademy("es"));

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  }, [dispatch]);
  useEffect(() => {
    if (isFirstUse.current) {
      setLoading(true);
      getItemsFromFB();
      isFirstUse.current = false;
    } else if (academyNews.length > 0) {
      setLoading(false);
    } else {
      const timeout = setTimeout(() => {
        setDataAvailable(false);
        setLoading(false);
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [academyNews, getItemsFromFB]);
  const handleOpenModal = (item) => {
    setSelectedItem(item);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedItem(null);
    setOpen(false);
  };

  return (
    <Box sx={styles.marginView}>
      <Grid container>
        <Grid item md={12} container justifyContent={"center"}>
          <AcademyCarousel
            items={academyNews}
            handleOpenModal={handleOpenModal}
          />
        </Grid>
      </Grid>
      <AcademyCategories
        imageOne={brain}
        imageTwo={shoe}
        imageThree={microphone}
      />
      <PreviewModal open={open} onClose={handleClose} item={selectedItem} />
    </Box>
  );
};
