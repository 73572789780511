import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setForex, setCryptos, setSynthetics } from "../../store/actions/items";
import {
  CircularProgress,
  Container,
  Grid,
  Typography,
  Tabs,
  Tab,
  Box,
  IconButton,
} from "@mui/material";
import VideoListItem from "../../components/academy/VideoListItem";
import { useTheme } from "@emotion/react";
import { MdRefresh } from "react-icons/md";
import { styles } from "../../../assets/styles/styles";
import { useParams } from "react-router-dom";
import { VideoPlayer } from "./VideoPlayer";
import ActiveModal from "../../components/modals/ActiveModal";

export const PlaylistTradingView = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const USERDATA = useSelector((state) => state.user.userData);
  const { category } = useParams();
  const [loading, setLoading] = useState(true);
  const [lastItem, setLastItem] = useState(null);
  const [dataAvailable, setDataAvailable] = useState(true);
  const [tabValue, setTabValue] = useState(0);
  const [items, setItems] = useState([]); // Use useState to manage 'items' state
  const [showVideoPlayer, setShowVideoPlayer] = useState(false);
  const { forex, cryptos, synthetics } = useSelector((state) => state.items);
  const [selectedVideo, setSelectedVideo] = useState("");
  const [videoDetails, setVideoDetails] = useState(null);
  const isFirstUse = useRef(true);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [status, setStatus] = useState("");
  const { academyScreen } = useSelector((state) => state.user.language);
  const getItemsFromFB = useCallback(async () => {
    try {
      setLoading(true);

      dispatch(setForex("es"));

      dispatch(setCryptos("es"));

      dispatch(setSynthetics("es"));

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, [dispatch, category]);

  useEffect(() => {
    if (USERDATA.isActive && !USERDATA.isGoldPlan) {
      setStatus("Activo");
    } else if (USERDATA.isGoldPlan) {
      setStatus("Gold");
    } else {
      setStatus("Inactivo");
    }
    console.log(status);
  }, [USERDATA, selectedVideo]);

  useEffect(() => {
    if (isFirstUse.current) {
      setLoading(true);
      setTabValue(0);
      getItemsFromFB();
      isFirstUse.current = false;
    } else {
      setLoading(true);
      if (category === "forex" || category === "cryptos") {
        const categoryData = category === "forex" ? forex : cryptos;

        if (categoryData) {
          const selectedTabItems =
            tabValue === 0
              ? categoryData.beginner
              : tabValue === 1
              ? categoryData.intermediate
              : categoryData.advanced;

          if (selectedTabItems?.length > 0) {
            const lastItemID =
              selectedTabItems[selectedTabItems.length - 1]?.id;
            setLastItem(lastItemID);
            setItems(selectedTabItems);
            setLoading(false);
            setDataAvailable(true);
          } else {
            const timeout = setTimeout(() => {
              setDataAvailable(false);
              setLoading(false);
            }, 2000);

            return () => clearTimeout(timeout);
          }
        }
      } else if (category === "pro" || category === "lab") {
        const categoryData =
          category === "pro" ? synthetics?.pro : synthetics?.lab;
        if (categoryData) {
          const selectedTabItems =
            tabValue === 0
              ? categoryData.beginner
              : tabValue === 1
              ? categoryData.intermediate
              : categoryData.advanced;

          if (selectedTabItems?.length > 0) {
            const lastItemID =
              selectedTabItems[selectedTabItems.length - 1]?.id;
            setLastItem(lastItemID);
            setItems(selectedTabItems);
            setLoading(false);
            setDataAvailable(true);
          } else {
            const timeout = setTimeout(() => {
              setDataAvailable(false);
              setLoading(false);
            }, 2000);

            return () => clearTimeout(timeout);
          }
        }
      }
    }
  }, [
    category,
    forex,
    cryptos,
    synthetics,
    getItemsFromFB,
    tabValue,
    dataAvailable,
  ]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);

    if (category === "forex") {
      setItems(
        newValue === 0
          ? forex.beginner
          : newValue === 1
          ? forex.intermediate
          : forex.advanced
      );
    } else if (category === "cryptos") {
      setItems(
        newValue === 0
          ? cryptos.beginner
          : newValue === 1
          ? cryptos.intermediate
          : cryptos.advanced
      );
    } else if (category === "pro") {
      setItems(
        newValue === 0
          ? synthetics?.pro?.beginner
          : newValue === 1
          ? synthetics?.pro?.intermediate
          : synthetics?.pro?.advanced
      );
    } else if (category === "lab") {
      setItems(
        newValue === 0
          ? synthetics?.lab?.beginner
          : newValue === 1
          ? synthetics?.lab?.intermediate
          : synthetics?.lab?.advanced
      );
    }
  };
  const toggleVideoPlayer = () => {
    setShowVideoPlayer(!showVideoPlayer);
  };
  const handleVideoItemClick = (item) => {
    let newAvailableVideo = false;
    console.log(item);
    if (status === "Activo") {
      if (!item?.isGoldPlan) {
        newAvailableVideo = true;
      }
    } else if (status === "Gold") {
      newAvailableVideo = true;
    } else if (status === "Inactivo") {
      if (!item?.isMembersOnly && !item?.isGoldPlan) {
        newAvailableVideo = true;
      }
    }

    if (newAvailableVideo) {
      setSelectedVideo(item.videoURL);
      setVideoDetails(item);
      toggleVideoPlayer();
    } else {
      setDialogOpen(true);
    }
  };
  return (
    <Box sx={styles.marginView}>
      {!showVideoPlayer ? (
        <Container>
          <Box sx={styles.cardStyle} className="content theme-card">
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              TabIndicatorProps={{
                style: { backgroundColor: theme.palette.primary.secondary },
              }}
            >
              <Tab
                label={academyScreen?.levelsMenu?.beginner?.name}
                value={0}
                style={
                  tabValue === 0
                    ? { color: theme.palette.primary.secondary }
                    : { color: "white" }
                }
              />
              <Tab
                label={academyScreen?.levelsMenu?.intermediate?.name}
                value={1}
                style={
                  tabValue === 1
                    ? { color: theme.palette.primary.secondary }
                    : { color: "white" }
                }
              />
              <Tab
                label={academyScreen?.levelsMenu?.advanced?.name}
                value={2}
                style={
                  tabValue === 2
                    ? { color: theme.palette.primary.secondary }
                    : { color: "white" }
                }
              />

              <div className="ml-auto mr-5  ">
                <IconButton color="success" onClick={() => getItemsFromFB()}>
                  <MdRefresh size={24} />
                </IconButton>
              </div>
            </Tabs>
          </Box>

          {!dataAvailable ? (
            <div>
              <Container>
                <Grid container mt={2}>
                  <Typography variant="h6">
                    No hay Videos Disponibles
                  </Typography>
                </Grid>
              </Container>
            </div>
          ) : (
            <>
              {loading ? (
                <Grid container mt={2}>
                  <CircularProgress />
                </Grid>
              ) : (
                <Grid container mt={4}>
                  {items?.map((item) => (
                    <VideoListItem
                      items={items}
                      key={item.id}
                      item={item}
                      lastItem={lastItem}
                      handleVideoItemClick={handleVideoItemClick}
                    />
                  ))}
                </Grid>
              )}
            </>
          )}
        </Container>
      ) : (
        <VideoPlayer
          videos={items}
          selectedVideo={selectedVideo}
          setSelectedVideo={setSelectedVideo}
          videoDetails={videoDetails}
          setVideoDetails={setVideoDetails}
          handleVideoItemClick={handleVideoItemClick}
        />
      )}
      <ActiveModal
        isDialogOpen={isDialogOpen}
        setDialogOpen={setDialogOpen}
        status={status}
      />
    </Box>
  );
};
