import Colors from "../../app/utils/Colors";

export const styles = {
  ionicons: {
    top: 15,
    left: 15,
  },
  ioniconsAndroid: {
    position: "absolute",
    top: 30,
    left: 15,
  },
  modalCard: {
    // top: -50,
    width: 375,
    height: "100%",
    boxShadow:
      "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;",
    backgroundColor: Colors.bgLight,
    alignSelf: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    py: 4,
    borderRadius: "36px",
  },
  modalBackground: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",

    backgroundColor: Colors.bgLight,
    alignSelf: "center",
    borderRadius: "36px",
  },
  modalBtn: {
    alignItems: "center",
    alignSelf: "center",
    justifyContent: "center",
    marginTop: 2,
    marginBottom: 0,
    height: 45,
    width: 200, //The Width must be the same as the height

    backgroundColor: "#000",
  },
  logoContainer: {
    width: "100%",
    alignSelf: "center",
    height: "100%",
    alignSelf: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    // bottom: 30,
  },
  logo: {
    width: 150,
    height: "100%",
    alignSelf: "center",
  },
  descriptionText: {
    color: Colors.light,
    fontSize: 15,
    alignSelf: "center",

    textAlign: "center",
    marginHorizontal: 5,
    marginTop: 0,
  },
  cardText: {
    fontSize: 15,
    color: "#fff",
    textAlign: "center",
    alignSelf: "center",

    marginHorizontal: 5,
  },
};
