import { Box, Container, useTheme } from "@mui/material";
import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import TopNavbar from "./TopNavbar";
import SideDrawer from "./SideDrawer";
import { VanguardNavbar } from "./shared/VanguardNavbar";

const SidebarLayout = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [firstUse, setFirstUse] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleDrawerToggle = () => {
    setOpen(!open);
    // console.log('opened ==', open);
  };
  const contentStyle = {
    marginLeft: !open ? "75px" : "calc(224px + 1px)",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      ...(!open && {
        transition: theme.transitions.create("margin", {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
      }),
    },
  };
  return (
    <>
      <SideDrawer
        sidebarOpen={open}
        handleDrawerToggle={handleDrawerToggle}
        setOpen={setOpen}
      />
      <Container maxWidth="false" sx={{ padding: "0px!important" }}>
        <Box
          sx={{
            ...contentStyle,
          }}
        >
          <Outlet />
        </Box>
      </Container>
    </>
  );
};

export default SidebarLayout;
