import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";

import MenuItem from "@mui/material/MenuItem";
import { useLocation, useNavigate } from "react-router-dom";

import { styled } from "@mui/material/styles";
// You can import the actual icon you want to use
import {
  AppBar,
  Badge,
  Grid,
  InputBase,
  Link,
  alpha,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { logoutUser } from "../store/actions/auth";

import LogoImage from "../../assets/img/V2.png";
import { firestoreRT, setUserDataRT } from "../store/actions/user";
import { styles } from "../../assets/styles/styles";
import Colors from "../utils/Colors";
import {
  MdArrowBack,
  MdNotifications,
  MdOutlineNotifications,
} from "react-icons/md";
import { getPhone } from "../store/actions/items";

const TopNavbar = ({ open, handleDrawerOpen, isMobile }) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const navigate = useNavigate();
  const { user, loggedIn } = useSelector((state) => state.auth);
  const USERDATA = useSelector((state) => state.user.userData);
  const [notificationCount, setNotificationCount] = useState(3); // Replace with your actual notification count
  const [backOn, setBackOn] = useState({ active: false, defaultRoute: null });

  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const aboveTablet = useMediaQuery(theme.breakpoints.up("md"));

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);
  const openNotificationMenu = Boolean(notificationAnchorEl);
  const [status, setStatus] = useState("");
  const defaultBgColor = Colors.grey2;
  const [previewImage, setPreviewImage] = useState(user?.photoURL);

  const badgeStyle = () => {
    if (USERDATA.isActive && !USERDATA.isGoldPlan) {
      return `linear-gradient(to right, ${Colors.greyLight}, ${defaultBgColor})`;
    } else if (USERDATA.isGoldPlan) {
      return `linear-gradient(to right, ${Colors.yellowAcademy}, ${Colors.yellowDark2})`;
    } else {
      return `linear-gradient(to right, ${Colors.grey}, ${Colors.grey})`;
    }
  };

  useEffect(() => {
    if (USERDATA?.photoURL) {
      setPreviewImage(USERDATA.photoURL);
    } else if (user?.photoURL) {
      setPreviewImage(user?.photoURL);
    } else {
      setPreviewImage("https://picsum.photos/200/200");
    }
  }, [previewImage]);

  useEffect(() => {
    if (USERDATA.isActive && !USERDATA.isGoldPlan) {
      setStatus("Basic");
    } else if (USERDATA.isGoldPlan) {
      setStatus("Luxe");
    } else {
      setStatus("Inactivo");
    }
  }, [USERDATA]);

  useEffect(() => {
    if (user) {
      const snap = firestoreRT()
        .collection("users")
        .doc(user.uid)
        .onSnapshot(
          async (userRT) => {
            dispatch(setUserDataRT(userRT.data()));
          },
          (error) => console.log(error),
          (userRTC) => console.log(userRTC)
        );

      return snap;
    }
  }, [user]);

  const loadItems = useCallback(async () => {
    try {
      console.log("Loading items...");
      dispatch(getPhone());
      console.log("Items loaded.");
    } catch (err) {
      console.log(err);
    }
  }, [dispatch, getPhone]);

  useEffect(() => {
    loadItems();
  }, [loadItems]);

  const handleNotificationMenu = (event) => {
    setNotificationAnchorEl(event.currentTarget);
  };

  const handleCloseNotificationMenu = () => {
    setNotificationAnchorEl(null);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleLogout = () => {
    dispatch(logoutUser());
    navigateTo("/auth/login", { replace: true });
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigateTo = (route) => {
    navigate(route);
    handleClose();
  };
  const goBack = (route) => {
    navigate(route); // Replace "/defaultRoute" with the desired default route
  };

  const Search = styled("div")(() => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    borderColor: "black",
    border: "1px solid grey",

    // backgroundColor: alpha(theme.palette.common.white, 0.75),
    // "&:hover": {
    //   backgroundColor: alpha(theme.palette.common.white, 0.25),
    // },
    marginLeft: 0,

    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  }));

  const location = useLocation();

  const [titleApp, setTitleApp] = useState("Inicio");

  useEffect(() => {
    switch (location.pathname) {
      case "/home":
        setTitleApp("Inicio");
        setBackOn({ active: false, defaultRoute: null });
        break;
      case "/academy":
        setTitleApp("Academia");
        setBackOn({ active: false, defaultRoute: null });
        break;
      case "/academy/playlist/onemindset":
        setTitleApp("Mindset");
        setBackOn({ active: true, defaultRoute: "/academy" });
        break;
      case "/academy/playlist/womanOfPower":
        setTitleApp("Woman Of Power");
        setBackOn({ active: true, defaultRoute: "/academy" });
        break;
      case "/academy/playlist/personalDev":
        setTitleApp("Podcast");
        setBackOn({ active: true, defaultRoute: "/academy" });
        break;
      case "/trading":
        setTitleApp("Trading");
        setBackOn({ active: false, defaultRoute: null });
        break;
      case "/trading/playlist/forex":
        setTitleApp("Forex");
        setBackOn({ active: true, defaultRoute: "/trading" });
        break;
      case "/trading/playlist/cryptos":
        setTitleApp("Academia");
        setBackOn({ active: true, defaultRoute: "/trading" });
        break;
      case "/trading/synthetics":
        setTitleApp("Índices Sintéticos");
        setBackOn({ active: true, defaultRoute: "/trading" });
        break;
      case "/trading/playlist/lab":
        setTitleApp("Power lab");
        setBackOn({ active: true, defaultRoute: "/trading/synthetics" });
        break;
      case "/trading/playlist/pro":
        setTitleApp("Pro Academy");
        setBackOn({ active: true, defaultRoute: "/trading/synthetics" });
        break;
      case "/notifications":
        setTitleApp("Notificaciones");
        setBackOn({ active: false, defaultRoute: null });
        break;
      case "/schedules":
        setTitleApp("Agendas");
        setBackOn({ active: false, defaultRoute: null });
        break;
      case "/profile":
        setTitleApp("Perfil");
        setBackOn({ active: false, defaultRoute: null });
        break;
      case "/signals":
        setTitleApp("Señales");
        setBackOn({ active: false, defaultRoute: null });
        break;
      default:
        setTitleApp("Inicio");
        setBackOn({ active: false, defaultRoute: null });
        break;
    }
  }, [location, setTitleApp]);

  const SearchIconWrapper = styled("div")(() => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "grey",
  }));

  const StyledInputBase = styled(InputBase)(() => ({
    color: "inherit",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      color: "grey",

      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "14ch",
        "&:focus": {
          width: "14ch",
        },
      },
    },
  }));
  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      disableScrollLock
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      marginTop: theme.spacing(1),
      minWidth: 100,

      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],

      borderRadius: "18px",

      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));

  return (
    <AppBar
      position="static"
      open={open}
      sx={{
        marginLeft: !open ? "75px" : "calc(224px + 1px)",

        transition: theme.transitions.create("margin", {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        [theme.breakpoints.down("sm")]: {
          marginLeft: 0,
          ...(!open && {
            transition: theme.transitions.create("margin", {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
          }),
        },

        pt: 1,

        zIndex: 1000,
        // mt: 1,
      }}
    >
      <Toolbar
        sx={{
          justifyContent: "space-between",
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          // marginLeft: isMobile ? "0px" : open ? "250px" : "75px",
          // marginRight: "75px",
        }}
      >
        {isMobile ? (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
        ) : null}

        {location.pathname !== "/home" ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              width: "100%",
            }}
          >
            {backOn.active && (
              <IconButton
                onClick={() => goBack(backOn.defaultRoute)}
                sx={{
                  background: `linear-gradient(to left, ${Colors.yellowDark}, ${Colors.yellowAcademy})`,
                  marginRight: 4,
                  borderRadius: 8,
                }}
              >
                <MdArrowBack size={24} color={Colors.bgAcademy} />
              </IconButton>
            )}
            <Typography
              variant="h6"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                color: "white",
                fontWeight: "bold",
              }}
            >
              {titleApp}
            </Typography>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                color: "white",
                fontWeight: "bold",
              }}
            >
              Bienvenido {USERDATA?.name ?? ""}
            </Typography>
          </Box>
        )}

        <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}></Box>
        <Box sx={{ flexGrow: 0, marginLeft: "auto" }}>{/* <MenuFlag/>  */}</Box>

        <Box
          container
          sx={{
            position: "relative",
            width: "110px",
            mt: 0.5,
            mb: 0.5,
            marginRight: !open ? "75px" : "calc(224px + 1px)",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            width: "100%",
            p: 1,
            [theme.breakpoints.down("sm")]: {
              marginRight: 0,
            },
          }}
        >
          {/* <Box>
            <Box
              sx={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "85px",
                height: "20px",
                borderRadius: "18px",
                mr: 4,
              }}
            >
              <Badge
                badgeContent={notificationCount}
                color="error"
                overlap="circular"
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
              >
                <IconButton onClick={handleNotificationMenu}>
                  <MdOutlineNotifications size={28} color={Colors.yellow} />
                </IconButton>
              </Badge>
              <StyledMenu
                id="notification-menu"
                MenuListProps={{
                  "aria-labelledby": "notification-menu-button",
                }}
                anchorEl={notificationAnchorEl}
                open={openNotificationMenu}
                onClose={handleCloseNotificationMenu}
              >
      
                <MenuItem onClick={handleCloseNotificationMenu}>
                  Notification 1
                </MenuItem>
                <MenuItem onClick={handleCloseNotificationMenu}>
                  Notification 2
                </MenuItem>
              </StyledMenu>
            </Box>
          </Box> */}
          <Box>
            <Avatar
              alt="User Avatar"
              src={previewImage}
              sx={{
                mr: 2,
                borderRadius: "8px",
                width: "50px",
                height: "45px",
              }}
            />
          </Box>

          <Box>
            <Box
              sx={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "85px",
                height: "20px",

                borderRadius: "18px",
                background: badgeStyle(),
              }}
            >
              <img
                src={LogoImage}
                alt="Logo"
                style={{
                  position: "absolute",
                  left: 6,
                  width: "15px",
                  height: "15px",
                  marginRight: "10px",
                }}
              />
              <Typography
                variant="body2"
                sx={{
                  color: "black",
                  fontSize: 12,
                  marginLeft: "5px",
                  fontFamily: "Montserrat",
                  fontWeight: "700",
                }}
              >
                {status}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  color: "white",
                  fontSize: 12,
                  marginLeft: "5px",
                  fontFamily: "Montserrat",
                }}
              >
                {USERDATA?.name + USERDATA?.lastname ?? "Usuario"}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  color: "white",
                  fontSize: 12,
                  marginLeft: "5px",
                  fontFamily: "Montserrat",
                }}
              >
                {USERDATA.email ?? "Correo"}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
export default TopNavbar;
