import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Grid, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import bull from "../../../assets/img/06-forex.png";
import coins from "../../../assets/img/07-sinteticos.png";
import coins2 from "../../../assets/img/08-cryptos.png";

import { AcademyCarousel } from "../../components/academy/AcademyCarousel";

import { TradingCategories } from "../../components/academy/TradingCategories";
import { setTrading } from "../../store/actions/items";
import PreviewModal from "../../components/modals/PreviewModal";
import { styles } from "../../../assets/styles/styles";

export const TradingView = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const { tradingNews } = useSelector((state) => state.items);

  const isFirstUse = useRef(true);

  const [loading, setLoading] = useState(true);

  const [dataAvailable, setDataAvailable] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);
  const getItemsFromFB = useCallback(async () => {
    try {
      setLoading(true);

      await dispatch(setTrading("es"));

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  }, [dispatch]);
  useEffect(() => {
    if (isFirstUse.current) {
      setLoading(true);
      getItemsFromFB();
      isFirstUse.current = false;
    } else if (tradingNews.length > 0) {
      setLoading(false);
    } else {
      const timeout = setTimeout(() => {
        setDataAvailable(false);
        setLoading(false);
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [tradingNews, getItemsFromFB]);
  const handleOpenModal = (item) => {
    setSelectedItem(item);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedItem(null);
    setOpen(false);
  };

  return (
    <Box sx={styles.marginView}>
      <Grid container>
        <Grid item md={12} container justifyContent={"center"}>
          <AcademyCarousel
            items={tradingNews}
            handleOpenModal={handleOpenModal}
          />
        </Grid>
      </Grid>
      <TradingCategories imageOne={bull} imageTwo={coins} imageThree={coins2} />
      <PreviewModal open={open} onClose={handleClose} item={selectedItem} />
    </Box>
  );
};
