import React, { useCallback, useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  Box,
  Divider,
  Tabs,
  Tab,
  useTheme,
} from "@mui/material";
import Colors from "../../utils/Colors";
import preview from "../../../assets/img/Group 1.png";

import { products } from "../../utils/productData";
import VgButton from "../../components/general/VgButton";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllProducts } from "../../store/actions/products";

const initialValues = {
  name: "",
  lastname: "",
  email: "",
  phone: "",
  country: "",
  city: "",
  address: "",
  zipCode: "",
};

const PaymentView = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const PRODUCTS = useSelector((state) => state.products.products);
  const isFirstUse = useRef(true);
  const [loading, setLoading] = useState(true);

  const [hidden, setHidden] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [selectedTab, setSelectedTab] = useState(0);

  const getProductsFromFB = useCallback(async () => {
    try {
      dispatch(getAllProducts());
    } catch (error) {
      console.log(error);
    }
  }, [dispatch]);
  useEffect(() => {
    if (isFirstUse.current) {
      getProductsFromFB();
      isFirstUse.current = false;
    } else if (!PRODUCTS) {
      getProductsFromFB();
    } else {
      setLoading(false);
    }
  }, [getProductsFromFB]);

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      // Handle form submission logic here
      console.log(values);
    },
  });

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleTabChange = (event, newTab) => {
    setSelectedTab(newTab);
  };

  return (
    <Grid
      container
      sx={{
        background: Colors.bgAcademy,
        height: "100vh",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Grid
        container
        item
        md={12}
        justifyContent="center"
        columnGap={2}
        rowGap={2}
        alignItems={"center"}
      >
        <Grid
          item
          container
          xs={12}
          sm={12}
          columnGap={2}
          justifyContent="center"
        >
          <Grid
            item
            xs={6}
            container
            justifyContent={"center"}
            direction={"row"}
          >
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              textColor="primary"
              indicatorColor="primary"
            >
              {PRODUCTS.map((product, index) => (
                <Tab key={index} label={product.name} sx={{ color: "white" }} />
              ))}
            </Tabs>
          </Grid>
        </Grid>

        {PRODUCTS.map((product, index) => (
          <Grid
            key={index}
            container
            item
            xs={6}
            rowGap={2}
            justifyContent={"center"}
            alignItems={"center"}
            sx={{ display: selectedTab === index ? "block" : "none" }}
          >
            <Grid
              container
              item
              xs={12}
              justifyContent="space-between"
              sx={{
                backgroundColor: Colors.bgLight,
                borderRadius: "12px",
                p: 2,
                flexDirection: "column",
              }}
            >
              <Typography variant="h6">Datos de la Orden:</Typography>
              <List
                sx={{
                  justifyContent: "space-between",
                  color: "white", // Set the text color for the entire list
                }}
              >
                <ListItem sx={{ p: 0 }}>
                  <ListItemText
                    primary={"Producto:"}
                    sx={{ color: "white", flex: 0.5 }}
                  />
                  <ListItemText
                    primary={product.name}
                    sx={{ color: "white", flex: 1 }}
                  />
                </ListItem>
                <ListItem sx={{ p: 0 }}>
                  <ListItemText
                    primary={"Matricula:"}
                    sx={{ color: "white", flex: 0.5 }}
                  />
                  <ListItemText
                    primary={product.initialPrice ? product.initialPrice : 0}
                    sx={{ color: "white", flex: 1 }}
                  />
                </ListItem>
                <ListItem sx={{ p: 0 }}>
                  <ListItemText
                    primary={"Precio Mensual:"}
                    sx={{ color: "white", flex: 0.5 }}
                  />
                  <ListItemText
                    primary={product.monthlyPrice ? product.monthlyPrice : 0}
                    sx={{ color: "white", flex: 1 }}
                  />
                </ListItem>
                <Divider />
                <ListItem sx={{ p: 0 }}>
                  <ListItemText
                    primary={"Total:"}
                    sx={{ color: "white", flex: 0.5 }}
                  />
                  <ListItemText
                    primary={
                      product.initialPrice && product.monthlyPrice
                        ? product.initialPrice + product.monthlyPrice
                        : 0
                    }
                    sx={{ color: "white", flex: 1 }}
                  />
                </ListItem>
              </List>
            </Grid>

            <Grid
              container
              item
              xs={12}
              justifyContent="space-between"
              sx={{
                backgroundColor: Colors.bgLight,
                borderRadius: "12px",
                p: 2,
                mt: 4,
                position: "relative",
                minHeight: "375px",
              }}
            >
              <>
                <Typography variant="h6">Beneficios:</Typography>
                {/* Render benefits for the selected package */}
                {product.benefits.map((benefit, index) => (
                  <ListItem sx={{ p: 0 }} key={index}>
                    <ListItemText
                      primary={`- ${benefit}`}
                      sx={{ color: "white" }}
                    />
                  </ListItem>
                ))}
                <Box
                  component={"img"}
                  src={preview}
                  sx={{
                    width: 300,
                    height: "auto",
                    position: "absolute",
                    right: -150,
                    bottom: -63,
                  }}
                />
              </>
            </Grid>
          </Grid>
        ))}
      </Grid>

      <Grid
        container
        item
        md={12}
        justifyContent="center"
        columnGap={2}
        sx={{ zIndex: 1000 }}
      >
        <VgButton
          fontSize={16}
          title={"Continuar"}
          width={200}
          height={40}
          color={Colors.text2}
          bgColor={Colors.light}
          bgColor2={Colors.grey2}
        />
      </Grid>
    </Grid>
  );
};

export default PaymentView;
