import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { styles } from "../../../assets/styles/scheduleStyles";
import VgButton from "../general/VgButton";
import Colors from "../../utils/Colors";

export const ScheduleGridItem = ({ day, onClick, isActive }) => {
  const theme = useTheme();

  const dayNames = {
    1: "Lunes",
    2: "Martes",
    3: "Miercoles",
    4: "Jueves",
    5: "Viernes",
    6: "Sabado",
    7: "Domingo",
  };

  const setDayTitle = () => {
    return dayNames[day];
  };

  return (
    <Box>
      <VgButton
        title={setDayTitle()}
        width={"100%"}
        height={35}
        fontSize={12}
        bgColor={isActive ? Colors.yellow : Colors.greyLight}
        bgColorB={isActive ? Colors.yellowDark2 : Colors.grey2}
        onPress={() => onClick(day)}
      />
      {/* <Button
        sx={{
          ...(isActive ? styles.mainViewEnabled : styles.mainViewDisabled),
          marginVertical: 7,
        }}
        onClick={() => onClick(day)}
      >
        <Typography sx={styles.dayTitle}>{setDayTitle()}</Typography>
      </Button> */}
    </Box>
  );
};
