import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  styled,
  useTheme,
  Grid,
  Container,
} from "@mui/material";

import { ScheduleGridItem } from "./ScheduleGridItem";
import { getMasterClassFB } from "../../store/actions/schedules";
import { styles } from "../../../assets/styles/scheduleStyles";

export const MasterclassContainer = () => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const events = useSelector((state) => state.schedules.masterclass);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const daysOfWeek = [1, 2, 3, 4, 5, 6, 7];
  const [selectedDay, setSelectedDay] = useState(1);

  const loadItems = useCallback(async () => {
    setError(null);
    setIsRefreshing(true);
    try {
      await dispatch(getMasterClassFB());
    } catch (err) {
      setError(err.message);
    }
    setIsRefreshing(false);
  }, [dispatch]);

  useEffect(() => {
    setIsLoading(true);
    loadItems().then(() => {
      setIsLoading(false);
    });
  }, [loadItems]);

  const handleDayClick = (day) => {
    setSelectedDay(selectedDay === day ? null : day);
  };
  const dayNames = {
    1: "monday",
    2: "tuesday",
    3: "wednesday",
    4: "thursday",
    5: "friday",
    6: "saturday",
    7: "sunday",
  };
  const convertHour = (hours, targetDay) => {
    if (!hours || !Array.isArray(hours)) {
      return ""; // Return an empty string if hours is not defined or not an array
    }

    const targetDayString = dayNames[targetDay];

    if (!targetDayString) {
      console.error("Invalid target day:", targetDay);
      return ""; // Return an empty string if the targetDay is not found
    }

    const targetHour = hours.find(
      (hourEntry) => hourEntry.day === targetDayString
    );

    if (targetHour) {
      const timeParts = targetHour.hour.split(":");
      const hours = Number(timeParts[0]);
      const minutes = Number(timeParts[1]);
      const ampm = hours >= 12 ? "pm" : "am";
      const formattedHours = hours % 12 || 12;

      return `${formattedHours}:${minutes < 10 ? "0" : ""}${minutes} ${ampm}`;
    }

    return ""; // Return an empty string if the targetDay is not found in hours
  };

  const showInfo = () => {
    const eventsForDay = events.filter((event) =>
      event.days.includes(selectedDay)
    );

    if (eventsForDay.length === 0) {
      return (
        <Grid
          item
          container
          md={12}
          flexDirection={"row"}
          sx={{ display: "flex", flexDirection: "row" }}
          justifyContent={"center"}
        >
          <Typography variant="h6" sx={{ textAlign: "center", mt: 4 }}>
            No hay eventos disponibles
          </Typography>
        </Grid>
      );
    }
    return eventsForDay.map((event) => {
      const date = convertHour(event.hours, selectedDay);
      const isActive = event.days.includes(selectedDay); // Check if selectedDay is in the array

      return (
        <Grid
          item
          key={event.id}
          md={12}
          flexDirection={"row"}
          sx={{ display: "flex", flexDirection: "row" }}
        >
          <Box sx={isActive ? styles.infoViewHourActive : styles.infoViewHour}>
            <Typography
              sx={isActive ? styles.infoViewTextActive : styles.infoViewText}
            >
              {date}
            </Typography>
          </Box>
          <Box
            style={
              date.active && isActive
                ? styles.infoViewInfoActive
                : styles.infoViewInfo
            }
          >
            {date.active && isActive ? (
              <Typography style={styles.liveText}>
                {scheduleScreen.live}
              </Typography>
            ) : null}
            <Box sx={styles.infoRow}>
              <Box>
                <Typography
                  noWrap
                  variant="h6"
                  sx={styles.infoViewInfoTextTitle}
                >
                  {event.title}
                </Typography>
                <Typography
                  variant="body2"
                  sx={styles.infoViewInfoTextSubTitle}
                >
                  {event.subtitle}
                </Typography>
              </Box>
              {event.link ? (
                // Open the link in a new tab on click
                <Typography
                  sx={styles.viewMore}
                  onClick={() => window.open(event.link, "_blank")}
                  style={{ cursor: "pointer" }}
                >
                  VER
                </Typography>
              ) : null}
            </Box>
          </Box>
        </Grid>
      );
    });
  };

  if (isLoading) {
    return (
      <LoadingContainer>
        <CircularProgress size={theme.spacing(4)} />
      </LoadingContainer>
    );
  }

  if (error) {
    return (
      <LoadingContainer>
        <Typography sx={{ marginBottom: theme.spacing(2) }}>{error}</Typography>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => loadItems()}
        >
          Intente de nuevo
        </Button>
      </LoadingContainer>
    );
  }

  return (
    <Container maxWidth={"false"}>
      <Grid
        container
        rowGap={2}
        px={2}
        columnGap={1}
        sx={{ flexWrap: { lg: "nowrap" } }}
        justifyContent={{
          xs: "space-between",
          sm: "space-around",
          md: "space-between",
          lg: "space-between",
        }}
      >
        {daysOfWeek.map((day) => (
          <Grid item key={day} xs={4} sm={4} md={4} lg={2}>
            <ScheduleGridItem
              day={day}
              isActive={selectedDay === day}
              onClick={handleDayClick}
            />
          </Grid>
        ))}
      </Grid>
      <Grid
        container
        rowGap={2}
        my={6}
        sx={{ minHeight: "35vh" }}
        justifyContent={"flex-start"}
        alignContent={"flex-start"}
      >
        {showInfo()}
      </Grid>
    </Container>
  );
};

const LoadingContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
}));

const StyledFlatList = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(2),
  width: "100%",
  height: "100%",
  overflowY: "auto",
}));
