import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  CircularProgress,
  Box,
  Button,
} from "@mui/material";
import { MdClose } from "react-icons/md";

import arrowLink from "../../../assets/img/arrowLink.png";
import zoomLink from "../../../assets/img/zoomLink.png";
import shareLink from "../../../assets/img/shareLink.png";
import useIsSafari from "../general/useIsSafari";

const PreviewModal = ({ open, onClose, item }) => {
  const [loading, setLoading] = useState(true);
  const isSafari = useIsSafari();
  console.log(item);
  useEffect(() => {
    if (item) {
      setLoading(false);
    }
  }, [item]);

  if (loading) {
    return (
      <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
        <Box sx={{ position: "relative", height: "200px" }}>
          <CircularProgress />
        </Box>
      </Dialog>
    );
  }

  const shareOptions = {
    title: item?.name,
    url: item?.videoURL ? item?.videoURL : item?.imageURL,
  };

  const renderShareButton = () => {
    if (true) {
      // item?.clickMe
      return (
        <Button
          onClick={() => navigator.clipboard.writeText(item?.clickMe)}
          style={{
            margin: "5px",
            color: "white",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box>
            <img
              src={shareLink}
              alt="Share"
              style={{ width: 48, height: 48, marginBottom: 8 }}
            />
            <Typography style={{ fontSize: 8 }}>Compartir</Typography>
          </Box>
        </Button>
      );
    }
  };

  const renderStreamButton = () => {
    if (item?.streamURL) {
      // item?.streamURL
      return (
        <Button
          onClick={() => window.open(item?.streamURL, "_blank")}
          style={{
            margin: "5px",
            color: "white",
            cursor: "pointer",
            zIndex: "10000",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <img
              src={zoomLink}
              alt="Zoom"
              style={{ width: 48, height: "auto", marginBottom: 8 }}
            />
            <Typography style={{ fontSize: 8 }}>Zoom</Typography>
          </Box>
        </Button>
      );
    }
  };

  const renderClickMeButton = () => {
    const isValidUrl = /^(ftp|http|https):\/\/[^ "]+$/.test(item?.clickMe);

    if (item?.clickMe && isValidUrl) {
      return (
        <Button
          onClick={() => window.open(item?.clickMe, "_blank")}
          style={{
            margin: "5px",
            color: "white",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box>
            <img
              src={arrowLink}
              alt="Enlace"
              style={{ width: 48, height: 48, marginBottom: 8 }}
            />

            <Typography style={{ fontSize: 8 }}>Enlace</Typography>
          </Box>
        </Button>
      );
    } else {
      console.warn("Invalid URL:", item?.clickMe);
      return null;
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth={"xs"}
      PaperProps={{
        elevation: 0,
        sx: {
          position: "relative",
          height: "100%",
          width: "auto",
          maxWidth: "100%",
          background: "transparent",
          overflow: "hidden",
          borderRadius: "18px",
        },
      }}
    >
      <IconButton
        style={{ position: "relative", marginLeft: "auto", color: "white" }}
        onClick={onClose}
      >
        <MdClose size={24} />
      </IconButton>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: "75%",
        }}
      >
        {isSafari ? (
          <img
            src={item?.imageURL}
            alt="Preview"
            style={{
              width: "100%",
              height: "100%",
              maxWidth: "375px",
              maxHeight: "650px",
              objectFit: "cover",
              borderRadius: "18px",
            }}
          />
        ) : (
          <img
            src={item?.imageURL}
            alt="Preview"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: "18px",
            }}
          />
        )}
        <Box
          sx={{
            position: "absolute",
            bottom: -150,
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            padding: "16px",
          }}
        >
          {renderShareButton()}
          {renderStreamButton()}
          {renderClickMeButton()}
        </Box>
      </Box>
      <DialogContent
        sx={{
          position: "relative",
          width: "100%",

          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          borderRadius: "18px",
          zIndex: "-100 !important",
          //   background: "rgba(0, 0, 0, 0.5)",
        }}
      ></DialogContent>
    </Dialog>
  );
};

export default PreviewModal;
