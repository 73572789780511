import { auth, db } from "../../../firebase/firebase-config";

import { types } from "../types/types";

export const GET_USER_DATA = "GET_USER_DATA";
export const SET_USER_DATA = "SET_USER_DATA";
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const SET_COUNTRY = "SET_COUNTRY";
export const LOGOUT = "LOGOUT";

export const firestoreRT = () => {
  return db;
};

export const getUserDataRT = (id) => {
  return db.collection("users").doc(id);
};

export const getNotificationsFromFB = () => {
  try {
    const notificationCollection = db
      .collection("notifications")
      .orderBy("cdate", "desc")
      .limit(20);

    return notificationCollection;
  } catch (error) {
    console.log(error);
  }
};

export const updateUserNotifications = async (uid, notificationId) => {
  try {
    const userRef = db.collection("users").doc(uid);
    await userRef.set(
      {
        notifications: firestore.FieldValue.arrayUnion(notificationId),
      },
      { merge: true }
    );
  } catch (error) {
    console.log(error);
  }
};

export const updateAllUserNotifications = async (uid, notificationIds) => {
  try {
    const userRef = db.collection("users").doc(uid);
    await userRef.update({
      notifications: firestore.FieldValue.arrayUnion(...notificationIds),
    });
  } catch (error) {
    console.log(error);
  }
};
export const getUserNotifications = (uid, onUpdate) => {
  try {
    const userRef = db.collection("users").doc(uid);

    return userRef.onSnapshot((snapshot) => {
      const userData = snapshot.data();
      const notifications = userData.notifications || [];
      onUpdate(notifications);
    });
  } catch (error) {
    console.log(error);
    return () => {}; // Return an empty function as the unsubscribe function
  }
};

export const getUserNotificationsAsync = async (uid) => {
  const userNotificationsSnapshot = await db.collection("users").doc(uid).get();

  const userNotificationsData = userNotificationsSnapshot.data();
  const userNotifications = userNotificationsData.notifications || [];

  return userNotificationsData;
};

export const setUserDataRT = (docSnapShot) => {
  return (dispatch) => {
    dispatch({
      type: SET_USER_DATA,
      userData: {
        displayName:
          docSnapShot.displayName ||
          docSnapShot.name + " " + docSnapShot.lastname,

        name: docSnapShot.name || "",
        lastname: docSnapShot.lastname || "",
        email: docSnapShot.email || 0,
        phone: docSnapShot.phone || 0,
        vanguardId: docSnapShot.vanguardId || 0,
        birthday: docSnapShot.birthday || 0,
        isActive: docSnapShot.isActive || false,
        isGoldPlan: docSnapShot.isGoldPlan || false,
        language: docSnapShot.language || "es",
        country: docSnapShot.country || "",
        city: docSnapShot.city || "",
        address: docSnapShot.address || "",
        personalId: docSnapShot.personalId || "",
        idType: docSnapShot.idType || "",
        photoURL: docSnapShot.photoURL || "",
      },
    });
  };
};

export const updateUser = async (uid, data) => {
  console.log(` UID - ${uid}`, data);
  try {
    await db.collection("users").doc(uid).set(data, { merge: true });

    console.log(`User data updated successfully: UID - ${uid}`, data);
  } catch (error) {
    console.error("Error updating user data:", error);
    // You might want to dispatch an action for error handling here
  }
};

export const setLanguage = (language) => {
  return (dispatch) => {
    dispatch({
      type: SET_LANGUAGE,
      language: language,
    });
  };
};

export const setCountry = (country) => {
  return (dispatch) => {
    dispatch({
      type: SET_COUNTRY,
      country: country,
    });
  };
};

export const logout = () => {
  return async (dispatch) => {
    dispatch({
      type: LOGOUT,
    });
  };
};
