import { useEffect, useState } from "react";

const useIsSafari = () => {
  const [isSafari, setIsSafari] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    setIsSafari(
      userAgent.indexOf("safari") !== -1 && userAgent.indexOf("chrome") === -1
    );
  }, []);

  return isSafari;
};

export default useIsSafari;
