//PAYMENTS

import { db } from "../../../firebase/firebase-config";
import { API_URL } from "../../variables/globals";
export const GET_PRODUCTS = "GET_PRODUCTS";

export const getAllProducts = () => {
  return async (dispatch) => {
    try {
      const data = await db.collection("products").get();
      const items = [];
      data.docs.forEach((doc) => {
        items.push({ ...doc.data(), id: doc.id });
      });

      dispatch(getProducts(items));
    } catch (error) {
      console.log(error);
      // Handle error if needed
    }
  };
};

export const setUserToSigma = async (data, token) => {
  const url = API_URL + "/sigma/users";

  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };

  try {
    const response = await fetch(url, options);
    const res = await response.json();
    return res;
  } catch (error) {
    throw new Error(error);
  }
};

// isWEB

export const setPayment = async (data, token) => {
  const url = API_URL + "/sigma/users";

  const options = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };

  try {
    const response = await fetch(url, options);
    const res = await response.json();
    return res;
  } catch (error) {
    throw new Error(error);
  }
};

export const setSale = async (data, token) => {
  const url = API_URL + "/payments/sale";

  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  try {
    const response = await fetch(url, options);
    const res = await response.json();

    return res;
  } catch (error) {
    throw new Error(error);
  }
};

export const setReSale = async (data, token) => {
  const url = API_URL + "/payments/resale";

  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  try {
    const response = await fetch(url, options);
    const res = await response.json();

    return res;
  } catch (error) {
    throw new Error(error);
  }
};

export const getProducts = (products) => ({
  type: GET_PRODUCTS,
  payload: {
    products,
  },
});
