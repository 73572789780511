import React from "react";
import { Box, Button, Grid } from "@mui/material";

import Colors from "../../utils/Colors";
import { useNavigate } from "react-router-dom";
import VgButton from "../general/VgButton";

export const AcademyCategories = ({ imageOne, imageTwo, imageThree }) => {
  const navigate = useNavigate();

  const navigateToPlaylist = (category) => {
    navigate(`/academy/playlist/${category}`);
  };

  return (
    <Grid container>
      <Grid item container md={4} justifyContent={"center"} my={4}>
        <Box
          sx={{
            height: "260px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            position: "relative",
          }}
        >
          <img
            src={imageOne}
            alt="Logo"
            style={{
              height: "220px",
            }}
          />
          <Box
            sx={{
              px: 6,
              position: "absolute",
              bottom: 15,
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <VgButton
              onPress={() => navigateToPlaylist("onemindset")}
              shadow
              bgColor={Colors.yellowAcademy}
              bgColorB={Colors.yellowDark}
              title={"MINDSET"}
            />
          </Box>
        </Box>
      </Grid>
      <Grid item container md={4} justifyContent={"center"} my={4}>
        <Box
          sx={{
            height: "260px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            position: "relative",
          }}
        >
          <img
            src={imageTwo}
            alt="Logo"
            style={{
              height: "220px",
            }}
          />
          <Box
            sx={{
              px: 6,
              position: "absolute",
              bottom: 15,
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <VgButton
              onPress={() => navigateToPlaylist("womanOfPower")}
              shadow
              bgColor={Colors.yellowAcademy}
              bgColorB={Colors.yellowDark2}
              title={"WOMAN OF POWER"}
            />
          </Box>
        </Box>
      </Grid>
      <Grid item container md={4} justifyContent={"center"} my={4}>
        <Box
          sx={{
            height: "260px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            position: "relative",
          }}
        >
          <img
            src={imageThree}
            alt="Logo"
            style={{
              height: "220px",
            }}
          />
          <Box
            sx={{
              px: 6,

              position: "absolute",
              bottom: 15,
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <VgButton
              onPress={() => navigateToPlaylist("personalDev")}
              shadow
              bgColor={Colors.yellowAcademy}
              bgColorB={Colors.yellowDark2}
              title={"PODCAST"}
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
