import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { styles } from "../../../assets/styles/styles";
import Colors from "../../utils/Colors";
import { MdPlayArrow } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const VideoListItem = ({ item, lastItem, handleVideoItemClick }) => {
  const videoURL = `${item.videoURL}`;
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    const vimeo = videoURL.includes("vimeo");
    if (vimeo) {
      fetch(`https://vimeo.com/api/oembed.json?url=${videoURL}`)
        .then((response) => response.json())
        .then((json) => {
          if (json.thumbnail_url) {
            setData(json.thumbnail_url);
          } else {
            setData(
              "https://firebasestorage.googleapis.com/v0/b/dikeff-core-b908e.appspot.com/o/images%2Fitems%2Fhome%2Fhqdefault.jpeg?alt=media&token=4022cf67-b871-4d6e-9d00-dda9819cb41e"
            );
          }
        })
        .catch((error) => console.error(error))
        .finally(() => setLoading(false));
    } else {
      const videoId = videoURL.substring(
        videoURL.lastIndexOf("v=") + 2,
        videoURL.lastIndexOf("&")
      );

      setData(`https://img.youtube.com/vi/${videoId}/0.jpg`);
      setLoading(false);
    }
  }, [videoURL]);

  // Use a ref to measure the text width
  const titleRef = useRef(null);

  // Measure the title text width
  const measureTitleWidth = () => {
    if (titleRef.current) {
      const characterCount = item.name.length;
      const maxCharacterCount = 20; // Adjust this value to your desired limit
      // Check if the character count exceeds the maximum
      if (characterCount > maxCharacterCount) {
        // Set the 'bottom' style to push it up
        titleRef.current.style.bottom = "20px";
      } else {
        // Reset the 'bottom' style
        titleRef.current.style.bottom = "0";
      }
    }
  };
  const renderDots = () => {
    const dotCount = 16; // Change this to the desired number of dots
    const dots = [];
    for (let i = 0; i < dotCount; i++) {
      dots.push(<Box style={styles.dot} key={i} />);
    }
    return dots;
  };

  // Call measureTitleWidth when the text is loaded
  useEffect(() => {
    measureTitleWidth();
  }, [item.name]);

  const renderProgressBar = () => {
    // You can customize this progress bar as needed
    if (isLoading) {
      return <CircularProgress color={Colors.primaryColor} />;
    } else {
      return (
        <Box
          sx={{
            display: "flex",
            position: "absolute",
            bottom: 0,
            flexDirection: "row",
            alignSelf: "center",
            width: "100%",
            height: 11,
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                backgroundColor: Colors.yellow,
                alignSelf: "center",
                width: "92%",
                height: 11,
                borderRadius: 18,
                alignItems: "center",
              }}
            />

            <IconButton
              sx={styles.iconButton}
              onClick={() => handleVideoItemClick(item)}
            >
              <MdPlayArrow size={36} fill="'black" />
            </IconButton>
          </Box>
        </Box>
      );
    }
  };

  return (
    <Grid item container lg={10} md={12} sx={styles.surface}>
      <Box sx={styles.layout}>
        <Box sx={styles.dotColumn}>
          <Box sx={styles.bigDotOutline}>
            <Box sx={styles.bigDotInline} />
          </Box>

          {lastItem !== item.id && renderDots()}
        </Box>
        <Box sx={styles.videoContainer}>
          {renderProgressBar()}
          <Typography
            variant="h3"
            sx={{ fontSize: 26, pb: 1, textTransform: "uppercase" }}
          >
            {item.name}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};

export default VideoListItem;
