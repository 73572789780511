import {
  SET_USER_DATA,
  UPDATE_USER_DATA,
  SET_LANGUAGE,
  SET_COUNTRY,
  LOGOUT,
} from "../actions/user";

import * as ES from "../../languages/es.json";

const initialState = {
  userData: [],
  language: ES,
  country: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_DATA:
      return {
        ...state,
        userData: action.userData,
      };
    case UPDATE_USER_DATA:
      return {
        ...state,
        userData: action.userData,
      };
    case SET_LANGUAGE:
      return {
        ...state,
        language: action.language,
      };
    case SET_COUNTRY:
      return {
        ...state,
        country: action.country,
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};
