import { configureStore } from "@reduxjs/toolkit";
import { authReducer } from "./reducer/authReducer";

import { itemsReducer } from "./reducer/itemsReducer";
import { usersReducer } from "./reducer/usersReducer";
import { signalsReducer } from "./reducer/signalsReducer";
import { schedulesReducer } from "./reducer/schedulesReducer";
import { notificationsReducer } from "./reducer/notificationsReducer";
import userReducer from "./reducer/userReducer";
import { productsReducer } from "./reducer/productsReducer";

export const store = configureStore(
  {
    reducer: {
      items: itemsReducer,
      auth: authReducer,
      signals: signalsReducer,
      users: usersReducer,
      user: userReducer,
      schedules: schedulesReducer,
      notifications: notificationsReducer,
      products: productsReducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  },
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
