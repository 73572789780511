import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { login } from "../../store/actions/auth";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { styles } from "../../../assets/styles/styles";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import regImg from "../../../assets/img/register.jpg";
import logo from "../../../assets/img/logos/logoColor.png";
import { Container, FormControl, useTheme } from "@mui/material";
import loginBG from "../../../assets/img/loginBG.png";
import Colors from "../../utils/Colors";

const loginCardStyle = {
  backgroundColor: `${styles.white}`,
  boxShadow: 0,
  borderRadius: 1,
  border: "none",
  marginTop: 8,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  maxWidth: 400,
};

const Register = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { errorMsg } = useSelector((state) => state.auth);
  const [loading, setloading] = useState(false);
  const navigateTo = (route) => {
    navigate(route);
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      name: "",
      lastname: "",
      country: "",
      phone: "",
      repeatPassword: "",
      agreeTerms: false, // New checkbox field
    },
    validationSchema: yup.object({
      email: yup
        .string("Ingresa un correo electrónico")
        .email("Ingresa un correo electrónico válido")
        .required("Email is required"),
      password: yup
        .string("Ingresa una contraseña")
        .min(6, "Mínimo 6 caractéres")
        .required("La contraseña es requerida"),
      name: yup.string("Ingresa tu nombre").required("Nombre es requerido"),
      lastname: yup
        .string("Ingresa tu apellido")
        .required("Apellido es requerido"),
      country: yup.string("Ingresa tu país").required("País es requerido"),
      phone: yup
        .string("Ingresa tu número de teléfono")
        .required("Número de teléfono es requerido"),
      repeatPassword: yup
        .string("Repite tu contraseña")
        .oneOf([yup.ref("password")], "Las contraseñas no coinciden")
        .required("Repite tu contraseña"),
      agreeTerms: yup
        .bool()
        .oneOf([true], "Debes aceptar los términos y condiciones"), // Validation for the new checkbox
    }),
    onSubmit: async (values) => {
      try {
        setloading(true);
      } catch (error) {
        console.error("Registration failed:", error);
        // Set the error message here
      }
    },
  });

  return (
    <Box
      sx={{
        display: "flex",
        background: Colors.bgAcademy,
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container sx={{ overflow: "hidden" }}>
        <Grid
          container
          item
          sm={12}
          xs={12}
          justifyContent={"center"}
          alignContent={"center"}
          alignItems={"center"}
          sx={{
            boxShadow: " rgba(0, 0, 0, 0.35) 0px 5px 15px;",
            borderRadius: "18px",
            [theme.breakpoints.down("sm")]: {
              boxShadow: " none",
            },
          }}
        >
          <Grid
            item
            md={6}
            sm={6}
            xs={0}
            sx={{
              height: "750px",
              backgroundColor: Colors.bgAcademy,
              backgroundImage: `url(${regImg})`, // Use the imported image
              backgroundSize: "cover",
              borderTopRightRadius: "0px",
              borderTopLeftRadius: "0px",
              borderTopLeftRadius: "18px",
              borderBottomLeftRadius: "18px",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "auto",
                [theme.breakpoints.down("sm")]: {
                  margin: "1em auto 2em auto",
                  width: "50%",
                  height: "auto",
                },
              }}
            ></Box>
          </Grid>

          <Grid
            item
            sm={6}
            md={6}
            lg={6}
            xs={10}
            container
            justifyContent="center"
            alignItems="center"
            sx={{
              height: "750px",
              backgroundColor: Colors.bgAcademy,
              backgroundImage: `url(${loginBG})`,
              backgroundSize: "cover",
              borderTopRightRadius: "18px",
              borderTopLeftRadius: "0px",
              borderBottomRightRadius: "18px",
              borderBottomLeftRadius: "0px",
              [theme.breakpoints.down("md")]: {
                backgroundSize: "1000px , 1000px",
                boxShadow: " rgba(0, 0, 0, 0.35) 0px 5px 15px;",
                borderRadius: "18px",
              },
            }}
          >
            <form
              onSubmit={formik.handleSubmit}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "65%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
                mb={1}
              >
                <img
                  src={logo}
                  alt="Home"
                  style={{ width: "25%", height: "auto" }}
                />
              </Box>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      label="Nombre"
                      type="text"
                      id="name"
                      variant="outlined"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      label="Apellido"
                      type="text"
                      id="lastname"
                      variant="outlined"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.lastname}
                      error={
                        formik.touched.lastname &&
                        Boolean(formik.errors.lastname)
                      }
                      helperText={
                        formik.touched.lastname && formik.errors.lastname
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      label="Correo"
                      type="text"
                      id="email"
                      variant="outlined"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      label="País"
                      type="text"
                      id="country"
                      variant="outlined"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.country}
                      error={
                        formik.touched.country && Boolean(formik.errors.country)
                      }
                      helperText={
                        formik.touched.country && formik.errors.country
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      label="Número de Teléfono"
                      type="text"
                      id="phone"
                      variant="outlined"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.phone}
                      error={
                        formik.touched.phone && Boolean(formik.errors.phone)
                      }
                      helperText={formik.touched.phone && formik.errors.phone}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      label="Repetir Contraseña"
                      type="password"
                      id="repeatPassword"
                      variant="outlined"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.repeatPassword}
                      error={
                        formik.touched.repeatPassword &&
                        Boolean(formik.errors.repeatPassword)
                      }
                      helperText={
                        formik.touched.repeatPassword &&
                        formik.errors.repeatPassword
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="agreeTerms"
                        onChange={formik.handleChange}
                        checked={formik.values.agreeTerms}
                        color="primary"
                      />
                    }
                    label="Confirmo el procesamiento de datos
                  de acuerdo a las politicas de privacidad"
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ mt: 4 }}
              >
                Registrarse
              </Button>
            </form>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                mt: 2,
              }}
            >
              <Typography variant="body1" sx={{ cursor: "pointer", mb: 1 }}>
                ¿YA TIENES CUENTA?
              </Typography>
              <Typography
                variant="body1"
                sx={{ cursor: "pointer" }}
                onClick={() => navigateTo("/auth/login")}
              >
                INICIA SESIÓN
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Register;
