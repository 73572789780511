import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { styles } from "../../assets/styles/styles";
import { HomeCarousel } from "../components/home/HomeCarousel";
import school from "../../assets/img/01-Icono-Academy.png";
import notebook from "../../assets/img/02-agenda.png";
import square from "../../assets/img/square.png";
import Colors from "../utils/Colors";
import { setEvents } from "../store/actions/items";
import { useNavigate } from "react-router-dom";
import VgButton from "../components/general/VgButton";
import PreviewModal from "../components/modals/PreviewModal";
export const HomeView = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const { recommended, items, success, events } = useSelector(
    (state) => state.items
  );
  const navigateTo = (route) => {
    navigate(route);
  };
  const isFirstUse = useRef(true);
  const [refreshing, setRefreshing] = useState(false);
  const [loading, setLoading] = useState(true);

  const [dataAvailable, setDataAvailable] = useState(true);

  const getItemsFromFB = useCallback(async () => {
    try {
      setLoading(true);

      dispatch(setEvents());

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, [dispatch]);

  useEffect(() => {
    if (isFirstUse.current) {
      setLoading(true);
      getItemsFromFB();
      isFirstUse.current = false;
    } else if (events?.length > 0) {
      setLoading(false);
    } else {
      const timeout = setTimeout(() => {
        setDataAvailable(false);
        setLoading(false);
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [events, getItemsFromFB]);
  const refresh = () => {
    setRefreshing(true);

    getItemsFromFB();

    setRefreshing(false);
  };
  useEffect(() => {
    console.log(events);
  }, [events]);

  const handleOpenModal = (item) => {
    setSelectedItem(item);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedItem(null);
    setOpen(false);
  };

  return (
    <Container sx={styles.marginView} maxWidth={"xxl"}>
      <Grid container>
        <Grid item md={12} container justifyContent={"center"}>
          <HomeCarousel items={events} handleOpenModal={handleOpenModal} />
        </Grid>
      </Grid>
      <Grid container mt={4}>
        <Grid item container md={12} lg={4} justifyContent={"center"} my={4}>
          <Box
            sx={{
              position: "relative",
              height: "240px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              position: "relative",
              [theme.breakpoints.down("md")]: {
                mt: 2,
              },
            }}
          >
            <Box sx={{ position: "absolute", top: -25 }}>
              <Typography variant="h5" sx={{ color: "white" }}>
                Explora Vanguard
              </Typography>
            </Box>
            <img
              src={school}
              alt="Logo"
              style={{
                height: isMobile ? "200px" : "220px",
              }}
            />
            <Box
              sx={{
                px: 6,
                position: "absolute",
                bottom: 0,
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <VgButton
                onPress={() => navigateTo("/academy")}
                shadow
                bgColor={Colors.yellowAcademy}
                bgColorB={Colors.yellowDark}
                title={"Academia"}
              />
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          container
          md={12}
          lg={4}
          justifyContent={{
            lg: "flex-start",
            xs: "center",
            sm: "center",
            md: "center",
          }}
          my={4}
          // my={{ md: 6, xs: 0, sm: 2 }}
        >
          <Box
            sx={{
              height: "240px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              position: "relative",
              [theme.breakpoints.down("md")]: {
                mt: 2,
              },
            }}
          >
            <img
              src={notebook}
              alt="Logo"
              style={{
                height: isMobile ? "200px" : "220px",
              }}
            />
            <Box
              sx={{
                px: 6,
                position: "absolute",
                bottom: 0,
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <VgButton
                onPress={() => navigateTo("/schedules")}
                shadow
                bgColor={Colors.yellowAcademy}
                bgColorB={Colors.yellowDark}
                title={"Agenda"}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item container md={12} lg={4} justifyContent={"center"} my={4}>
          <Box
            sx={{
              alignItems: "flex-start",
              display: "flex",
              width: "100%",
              flexDirection: "column",
              [theme.breakpoints.down("lg")]: {
                alignItems: "center",
                mb: 2,
              },
            }}
          >
            <Typography variant="h6" sx={{ color: "white" }}>
              TU MEMBRESÍA
            </Typography>
            <Typography variant="h6" sx={{ color: "white" }}>
              VENCERÁ EN:
            </Typography>
          </Box>
          <Grid container justifyContent={{ xs: "center", md: "flex-start" }}>
            <Grid item md={2} lg={3}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "85px",

                  position: "relative",
                }}
              >
                <Box
                  component={"img"}
                  src={square}
                  alt="Logo"
                  sx={{
                    height: "100%",
                    maxWidth: "100%",
                  }}
                />
                <Typography
                  variant="h3"
                  sx={{
                    color: "white",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 1, // Ensure the number stays on top of the image
                  }}
                >
                  0
                </Typography>
              </Box>
            </Grid>

            <Grid item md={2} lg={3}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "85px",

                  position: "relative",
                }}
              >
                <Box
                  component={"img"}
                  src={square}
                  alt="Logo"
                  sx={{
                    height: "100%",
                    maxWidth: "100%",
                  }}
                />
                <Typography
                  variant="h3"
                  sx={{
                    color: "white",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 1, // Ensure the number stays on top of the image
                  }}
                >
                  0
                </Typography>
              </Box>
            </Grid>
            <Grid item md={2} lg={3}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "85px",

                  position: "relative",
                }}
              >
                <Box
                  component={"img"}
                  src={square}
                  alt="Logo"
                  sx={{
                    height: "100%",
                    maxWidth: "100%",
                  }}
                />
                <Typography
                  variant="h3"
                  sx={{
                    color: "white",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 1, // Ensure the number stays on top of the image
                  }}
                >
                  0
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <PreviewModal open={open} onClose={handleClose} item={selectedItem} />
    </Container>
  );
};
