import React, { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {
  Grid,
  IconButton,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Container,
  Box,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { MdFullscreen } from "react-icons/md";
import Slider from "react-slick";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <IconButton
      onClick={onClick}
      sx={{
        position: "absolute",
        top: "50%",

        right: -30,
        zIndex: 2,
      }}
    >
      <ArrowForwardIcon />
    </IconButton>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <IconButton
      onClick={onClick}
      sx={{
        position: "absolute",
        top: "50%",
        left: -30,
        zIndex: 2,
      }}
    >
      <ArrowBackIcon />
    </IconButton>
  );
}

export const AcademyCarousel = ({ items, handleOpenModal }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleOpenModalButtonClick = (selectedItem, selectedIndex) => {
    handleOpenModal(selectedItem, selectedIndex);
  };
  const mouseDownCoords = (e) => {
    window.checkForDrag = e.clientX;
  };

  const clickOrDrag = (e, slide, index) => {
    console.log("clickOrDrag");
    const mouseUp = e.clientX;
    if (
      mouseUp < window.checkForDrag + 5 &&
      mouseUp > window.checkForDrag - 5
    ) {
      handleOpenModalButtonClick(slide, index);
    }
  };

  const imageStyle = {
    width: "auto",
    height: "100%",
    maxHeight: isMobile ? "425px" : "500px",
    borderRadius: "18px",
    maxWidth: "365px",
    margin: "auto",
    transform: "scale(1)",
    transition: "transform 0.5s ease-in-out",
    // [theme.breakpoints.down("lg")]: {
    //   margin: "auto !important",
    //   maxWidth: "225px",
    // },
    // [theme.breakpoints.down("sm")]: {
    //   maxWidth: "225px",
    // },
  };
  const imageStyle2 = {
    width: "auto",
    height: "500px",
    maxHeight: isMobile ? "425px" : "500px",
    borderRadius: "18px",
    maxWidth: "365px",
    margin: "auto",
    transform: "scale(0.9)",
    transition: "transform 0.5s ease-in-out",

    // [theme.breakpoints.down("lg")]: {
    //   maxWidth: "225px",
    // },
    // [theme.breakpoints.down("sm")]: {
    //   maxWidth: "225px",
    // },
  };

  const settings = {
    infinite: true,
    centerPadding: "60px",
    initialSlide: 1,
    slidesToShow: items.length > 2 ? 3 : items.length < 2 ? 1 : 2,
    speed: 400,
    swipeToSlide: true, // Enable touch sliding
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  const responsiveSettings = [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
      },
    },
  ];
  if (!items) {
    return (
      <Grid container justifyContent="center">
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Grid container justifyContent="center" alignItems="center" my={5}>
      <Grid
        item
        container
        md={10}
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "500px",
          position: "relative",
          borderRadius: "18px",
          position: "relative",
          overflow: "hidden",

          [theme.breakpoints.down("sm")]: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            maxWidth: "95%",
            height: "425px",
          },
        }}
      >
        <Container maxWidth={"xxl"} className="slider-container">
          <Slider
            responsive={responsiveSettings}
            {...settings}
            beforeChange={(oldIndex, newIndex) => setSelectedIndex(newIndex)}
          >
            {items.map((slide, index) => (
              <div
                key={index}
                style={{
                  width: "100%",

                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  component="img"
                  src={slide.imageURL}
                  sx={selectedIndex + 1 === index ? imageStyle : imageStyle2}
                  alt={`Image ${index + 1}`}
                  onMouseDown={(e) => mouseDownCoords(e)}
                  onMouseUp={(e) => clickOrDrag(e, slide, index)}
                />
              </div>
            ))}
          </Slider>
        </Container>

        {/* <IconButton
          onClick={handleOpenModalButtonClick}
          style={{
            position: "absolute",
            bottom: "16px",
            right: "16px",
            zIndex: 3,
            cursor: "pointer",
          }}
        >
          <MdFullscreen size={48} color="white" />
        </IconButton> */}
      </Grid>
    </Grid>
  );
};
