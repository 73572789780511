import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCommunity } from "../../store/actions/items";
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { PlaylistContainer } from "../../components/academy/PlaylistContainer";
import { useParams } from "react-router-dom";
import VideoListItem from "../../components/academy/VideoListItem";
import { useTheme } from "@emotion/react";
import { styles } from "../../../assets/styles/styles";
import { VideoPlayer } from "./VideoPlayer";
import ActiveModal from "../../components/modals/ActiveModal";

export const PlaylistView = () => {
  const theme = useTheme();

  const dispatch = useDispatch();
  const USERDATA = useSelector((state) => state.user.userData);
  const { category } = useParams();
  const [loading, setLoading] = useState(true);
  const [lastItem, setLastItem] = useState(null);
  const [dataAvailable, setDataAvailable] = useState(false);
  const { womanOfPower, personalDev, onemindset } = useSelector(
    (state) => state.items
  );
  const [videoDetails, setVideoDetails] = useState(null);
  const [showVideoPlayer, setShowVideoPlayer] = useState(false);
  const isFirstUse = useRef(true);
  const [items, setItems] = useState([]); // Use useState to manage 'items' state
  const [selectedVideo, setSelectedVideo] = useState("");
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [status, setStatus] = useState("");
  const [availableVideo, setAvailableVideo] = useState(false);

  const getItemsFromFB = useCallback(async () => {
    try {
      setLoading(true);
      dispatch(setCommunity("es"));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, [dispatch]);

  useEffect(() => {
    if (USERDATA.isActive && !USERDATA.isGoldPlan) {
      setStatus("Activo");
    } else if (USERDATA.isGoldPlan) {
      setStatus("Gold");
    } else {
      setStatus("Inactivo");
    }
    console.log(status);
  }, [USERDATA, selectedVideo]);

  useEffect(() => {
    if (isFirstUse.current) {
      setLoading(true);
      getItemsFromFB();
      isFirstUse.current = false;
    } else if (
      (category === "onemindset" && onemindset?.length > 0) ||
      (category === "womanOfPower" && womanOfPower?.length > 0) ||
      (category === "personalDev" && personalDev?.length > 0)
    ) {
      if (category === "onemindset") {
        setLastItem(onemindset[onemindset.length - 1]?.id);
        setItems(onemindset);
        setDataAvailable(true); // Set 'items' state for rendering
      } else if (category === "womanOfPower") {
        setLastItem(womanOfPower[womanOfPower.length - 1]?.id);
        setItems(womanOfPower);
        setDataAvailable(true);
      } else if (category === "personalDev") {
        setLastItem(personalDev[personalDev.length - 1]?.id);
        setItems(personalDev);
        setDataAvailable(true);
      }
      setLoading(false);
    } else {
      const timeout = setTimeout(() => {
        setDataAvailable(false);
        setLoading(false);
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [category, womanOfPower, personalDev, onemindset, getItemsFromFB]);

  if (!dataAvailable) {
    return (
      <div>
        <Container>
          <Grid container>
            <Typography>No hay Videos Disponibles</Typography>
          </Grid>
        </Container>
      </div>
    );
  }
  const toggleVideoPlayer = () => {
    setShowVideoPlayer(!showVideoPlayer);
  };
  const handleVideoItemClick = (item) => {
    let newAvailableVideo = false;
    console.log(item);
    if (status === "Activo") {
      if (
        (item?.isMembersOnly && !item?.isGoldPlan) ||
        (!item?.isMembersOnly && !item?.isGoldPlan)
      ) {
        newAvailableVideo = true;
      }
    } else if (status === "Gold") {
      newAvailableVideo = true;
    } else if (status === "Inactivo") {
      if (!item?.isMembersOnly && !item?.isGoldPlan) {
        newAvailableVideo = true;
      }
    }

    if (newAvailableVideo) {
      setSelectedVideo(item.videoURL);
      setVideoDetails(item);
      toggleVideoPlayer();
    } else {
      setDialogOpen(true);
    }
  };

  return (
    <Box sx={styles.marginView}>
      {!showVideoPlayer ? (
        <Container>
          {!dataAvailable ? (
            <div>
              <Container>
                <Grid container mt={6}>
                  <Typography variant="h6">
                    No hay Videos Disponibles
                  </Typography>
                </Grid>
              </Container>
            </div>
          ) : (
            <>
              {loading ? (
                <Grid container>
                  <CircularProgress />
                </Grid>
              ) : (
                <Grid container mt={8} item>
                  {items?.map((item) => (
                    <VideoListItem
                      items={items}
                      key={item.id}
                      item={item}
                      lastItem={lastItem}
                      handleVideoItemClick={handleVideoItemClick}
                    />
                  ))}
                </Grid>
              )}
            </>
          )}
        </Container>
      ) : (
        <VideoPlayer
          videos={items}
          isActive={USERDATA.isActive}
          isGoldUser={USERDATA.isGoldPlan}
          selectedVideo={selectedVideo}
          setSelectedVideo={setSelectedVideo}
          videoDetails={videoDetails}
          handleVideoItemClick={handleVideoItemClick}
        />
      )}
      <ActiveModal
        isDialogOpen={isDialogOpen}
        setDialogOpen={setDialogOpen}
        status={status}
      />
    </Box>
  );
};
