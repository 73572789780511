import Colors from "../../app/utils/Colors";
import theme from "./darkTheme";
import bg from "../img/01-Fondo-APP.jpg";

export const styles = {
  row: {
    display: "flex",
    flexDirection: "row",
  },

  infoRow: {
    display: "flex",
    width: 350, // Adjust to the desired pixel size
    minWidth: 350, // Adjust to the desired pixel size
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: 2,
    pl: 2,
  },

  marginRight: {
    marginRight: 1,
  },

  rowInsideDescription: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginVertical: 2.5,
  },
  dividerLine: {
    display: "flex",
    flex: 1,
    backgroundColor: Colors.grey,
  },
  ionicons: {
    alignSelf: "center",
  },

  mainViewMarginVert7: {
    marginVertical: 7,
  },
  mainViewMarginVert5: {
    marginVertical: 5,
  },
  mainViewEnabled: {
    display: "flex",
    height: 55,
    width: 180, // Adjust to the desired pixel size
    borderBottomRightRadius: 20,
    borderTopRightRadius: 20,
    justifyContent: "center",
    alignItems: "flex-end",
  },
  mainViewDisabled: {
    display: "flex",
    height: 55,
    width: 180, // Adjust to the desired pixel size
    borderBottomRightRadius: 20,
    borderTopRightRadius: 20,
    justifyContent: "center",
    alignItems: "flex-end",
  },
  dayTitle: {
    display: "flex",
    marginHorizontal: 20,
    fontSize: 20,
    color: Colors.text2,
    fontFamily: "Montserrat", // Assuming montserratBold is defined
  },
  infoViewHour: {
    display: "flex",
    height: 55,
    width: 200, // Adjust to the desired pixel size
    backgroundColor: Colors.bg,
    borderBottomRightRadius: 20,
    borderTopRightRadius: 20,
    justifyContent: "center",
    alignItems: "flex-end",
    marginVertical: 1,
    border: `2px solid ${Colors.grey}`,
    borderTopWidth: 2,
    borderRightWidth: 2,
    borderBottomWidth: 2,
    borderLeftWidth: 2,
    borderColor: Colors.grey,
  },
  infoViewHourActive: {
    display: "flex",
    height: 55,
    width: 200, // Adjust to the desired pixel size
    backgroundColor: "#1B2634",
    borderBottomRightRadius: 20,
    borderTopRightRadius: 20,
    borderBottomLeftRadius: 8,
    borderTopLeftRadius: 8,
    justifyContent: "center",
    alignItems: "center",
    border: `2px solid ${Colors.grey}`,
    marginVertical: 1,
    borderTopWidth: 2,
    borderLeftWidth: 2,
    borderRightWidth: 2,
    borderBottomWidth: 2,
    borderLeftWidth: 2,
    borderColor: Colors.grey,
  },
  infoViewText: {
    marginLeft: 1.5,
    marginRight: 1,
    fontSize: 22,
    color: Colors.grey,
    fontFamily: "Montserrat",
  },
  infoViewTextActive: {
    marginLeft: 1.5,
    marginRight: 1,
    textTransform: "uppercase",
    fontSize: 22,
    fontWeight: "bold",
    color: Colors.light,
    textAlign: "center",
    fontFamily: "TruenoExBdIt",
  },
  infoViewInfo: {
    display: "flex",
    height: 55,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginVertical: 1,
  },
  infoViewInfoActive: {
    display: "flex",
    height: 55,
    width: 250, // Adjust to the desired pixel size
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginVertical: 1,
  },
  infoViewInfoTextTitle: {
    display: "flex",
    // marginLeft: 1,
    marginRight: 2,
    fontSize: 15,
    color: Colors.light,
    fontFamily: "Montserrat", // Assuming montserratBold is defined
    textAlign: "left",
  },
  infoViewInfoTextSubTitle: {
    display: "flex",

    fontSize: 12,
    color: Colors.light,
    fontWeight: "500",
    fontFamily: "Montserrat", // Assuming montserratRegular is defined
  },
  viewMore: {
    fontSize: 24,
    color: Colors.yellow,
    fontWeight: "bold",
    fontFamily: "Trueno",
  },
  liveText: {
    marginHorizontal: 30,
    fontSize: 10,
    color: Colors.third,
    fontFamily: "Trueno",
    left: 100, // Adjust to the desired pixel size
    position: "absolute",
    top: -15,
  },

  overallView: {
    flex: 1,
    minHeight: "100vh",
    backgroundColor: Colors.bgAcademy,
    background: `url(${bg})`,
    backgroundSize: "cover",
    // background: "red",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    // py: 4,
  },
  categoryBtn: {
    marginTop: theme.spacing(2),
  },
};
