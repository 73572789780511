import React, { useState } from "react";

import { useSelector } from "react-redux";

import { MasterclassContainer } from "../../components/schedules/MasterclassContainer";
import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import Colors from "../../utils/Colors";
import { styles } from "../../../assets/styles/scheduleStyles";
import VgButton from "../../components/general/VgButton";
import { SessionsContainer } from "../../components/schedules/SessionsContainer";
export const ScheduleView = ({ navigation }) => {
  const LOGGEDIN = useSelector((state) => state.auth.loggedIn);
  const USERDATA = useSelector((state) => state.user.userData);

  const [selectedCategory, setSelectedCategory] = useState(0);

  const theme = useTheme();

  const categories = ["Trading", "Masterclass"];

  const changeSelectedData = (index) => {
    switch (index) {
      case 0:
        return <SessionsContainer />;
      case 1:
        return <MasterclassContainer />;

      default:
        break;
    }
  };

  //   if (!LOGGEDIN) {
  //     return <NewUserModalScreen {...navigation} />;
  //   } else {
  //     if (!USERDATA.isActive) {
  //       return <ActiveModalScreen {...navigation} />;
  //     } else {
  //       return (
  //         <Box sx={styles.overallView}>
  //           <Grid container spacing={2} sx={styles.categoryBtn}>
  //             {categories.map((category, index) => (
  //               <Grid item key={index}>
  //                 <Button
  //                   variant="contained"
  //                   size="small"
  //                   sx={{
  //                     width: "100%",
  //                     backgroundColor:
  //                       index === selectedCategory
  //                         ? Colors.yellow
  //                         : Colors.greyLight,
  //                     color:
  //                       index === selectedCategory ? Colors.text1 : Colors.text2,
  //                   }}
  //                   onClick={() => setSelectedCategory(index)}
  //                 >
  //                   {category}
  //                 </Button>
  //               </Grid>
  //             ))}
  //           </Grid>
  //           {changeSelectedData(selectedCategory)}
  //         </Box>
  //       );
  //     }
  //   }
  // };

  return (
    <Box sx={styles.overallView}>
      <Box mt={6}>{changeSelectedData(selectedCategory)}</Box>
      <Grid
        container
        spacing={2}
        sx={styles.categoryBtn}
        flexDirection={"column"}
        ml={6}
      >
        <Typography variant="h4">Opciones</Typography>
      </Grid>
      <Grid
        container
        spacing={2}
        sx={styles.categoryBtn}
        ml={4}
        alignItems={"flex-start"}
        justifyContent={"flex-start"}
      >
        {categories.map((category, index) => (
          <Grid
            item
            md={3}
            xs={4}
            sm={4}
            lg={2}
            key={index}
            container
            alignItems={"flex-start"}
            justifyContent={"flex-start"}
          >
            <VgButton
              title={category}
              width={"100%"}
              height={35}
              fontSize={12}
              bgColor={
                index === selectedCategory ? Colors.yellow : Colors.greyLight
              }
              bgColorB={
                index === selectedCategory ? Colors.yellowDark2 : Colors.grey2
              }
              onPress={() => setSelectedCategory(index)}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
