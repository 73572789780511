import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import Login from "./../views/auth/Login";
import SidebarLayout from "./../components/SidebarLayout";
import { PrivateRoute } from "./PrivateRoute";
import { useDispatch, useSelector } from "react-redux";
import { auth } from "../../firebase/firebase-config";
import {
  logoutUser,
  setIsLoadingAuth,
  signInWithEmail,
} from "../store/actions/auth";
import { Typography } from "@mui/material";
import { HomeView } from "../views/HomeView";

import { ProfileView } from "../views/profile/ProfileView";
import { AcademyView } from "../views/academy/AcademyView";
import { TradingView } from "../views/academy/TradingView";
import { PlaylistView } from "../views/academy/PlaylistView";
import { PlaylistTradingView } from "../views/academy/PlaylistTradingView";
import { SyntheticCategoryView } from "../views/academy/SyntheticCategoryView";
import { VideoPlayer } from "../views/academy/VideoPlayer";
import { ScheduleView } from "../views/schedules/ScheduleView";
import Register from "../views/auth/Register";
import PaymentCommerce from "../views/payment/PaymentCommerce";
import PaymentView from "../views/payment/PaymentView";
// import PaymentView from "../views/payment/PaymentView";

function AppRoutes() {
  const dispatch = useDispatch();
  const history = useLocation();
  const { loggedIn, isLoadingAuth } = useSelector((state) => state.auth);
  const lastUrl = localStorage.getItem("lastUrl") || "/home";
  const [isAuthResolved, setIsAuthResolved] = useState(false);
  console.log(loggedIn);
  useEffect(() => {
    const handleAuthStateChange = async (authUser) => {
      dispatch(setIsLoadingAuth(true));

      if (authUser) {
        try {
          const metadata = await authUser.getIdTokenResult();
          dispatch(signInWithEmail(authUser.toJSON(), metadata.claims));
          sessionStorage.setItem("user", JSON.stringify(authUser.toJSON()));
        } catch (error) {
          // Handle error, e.g., log it or dispatch an action
        }
      } else {
        sessionStorage.removeItem("user");
        dispatch(logoutUser());
      }

      dispatch(setIsLoadingAuth(false));
      setIsAuthResolved(true);
    };

    const unsubscribe = auth.onAuthStateChanged(handleAuthStateChange);

    // Cleanup subscription when component unmounts
    return () => unsubscribe();
  }, [dispatch, loggedIn]);

  // if (
  //   metadata.claims.superadmin ||
  //   metadata.claims.admin ||
  //   metadata.claims.almighty
  // ) {
  //   dispatch(signInWithEmail(user.toJSON(), metadata.claims));
  //   sessionStorage.setItem("user", JSON.stringify(user.toJSON()));
  // } else {
  //   sessionStorage.removeItem("user");
  //   dispatch(logoutUser());
  // }

  // useEffect(() => {
  //   const handleBeforeUnload = () => {
  //     localStorage.setItem("lastUrl", history.pathname);
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, [history]);

  if (isLoadingAuth || !isAuthResolved) {
    return (
      <div id="login">
        <div className="login-wrapper ">
          <div className="align">
            <Typography component="h1" variant="h5">
              Cargando ...
            </Typography>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <Routes>
        <Route path="/" element={<SidebarLayout />}>
          <Route
            path=""
            element={
              loggedIn ? (
                <Navigate replace to={lastUrl} />
              ) : (
                <Navigate to="/auth/login" />
              )
            }
          />
          {/* ERROR PAGE <Route path="*" element={<Dashboard />} /> */}
          <Route
            path="/*"
            element={
              <PrivateRoute isLoggedIn={loggedIn}>
                <Routes>
                  <Route
                    path="/auth/login"
                    element={
                      <Navigate
                        replace
                        to={lastUrl === "/auth/login" ? "/home" : lastUrl}
                      />
                    }
                  />
                  <Route index path="/home" element={<HomeView />} />

                  <Route path="/home" element={<HomeView />} />
                  <Route path="/profile" element={<ProfileView />} />
                  <Route path="/academy" element={<AcademyView />} />
                  <Route path="/trading" element={<TradingView />} />
                  <Route path="/videoplayer" element={<VideoPlayer />} />
                  <Route
                    path="/academy/playlist/:category"
                    element={<PlaylistView />}
                  />
                  <Route
                    path="/trading/playlist/:category"
                    element={<PlaylistTradingView />}
                  />
                  <Route
                    path="/trading/synthetics"
                    element={<SyntheticCategoryView />}
                  />
                  <Route path="/payment" element={<PaymentView />} />
                  {/* <Route path="/payment" element={<PaymentView />} /> */}
                  <Route path="/schedules" element={<ScheduleView />} />
                </Routes>
              </PrivateRoute>
            }
          />
        </Route>
        <Route path="/payment" element={<PaymentView />} />
        {!loggedIn ? (
          <Route Route path="/auth">
            <Route path="" element={<Navigate replace to="login" />} />
            <Route path="login" element={<Login />} />
            <Route path="register" element={<Register />} />
          </Route>
        ) : null}
      </Routes>
    </>
  );
}

export default AppRoutes;
