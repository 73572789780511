import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { handleResetPassword } from "../../store/actions/auth";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import VgButton from "../general/VgButton";
import Colors from "../../utils/Colors";
import { Box, IconButton } from "@mui/material";
import { MdClose } from "react-icons/md";

const MySwal = withReactContent(Swal);

export const ForgotPassword = ({ handleClose, openDialog, email }) => {
  const handleForgotPassword = async () => {
    try {
      const errorMessage = await handleResetPassword(email);

      if (!errorMessage) {
        // Password reset successful
        handleClose(); // Close the dialog after submitting the email
        // Show success message using sweetalert2
        MySwal.fire({
          icon: "success",
          title: "Revisa tu correo electrónico",
          text: "Se ha enviado un correo electrónico para cambiar tu contraseña.",
          confirmButtonColor: Colors.yellowAcademy,
        });
        setResetEmailSent(true); // Update component state
      } else {
        // Password reset failed, display error message
        MySwal.fire({
          icon: "error",
          title: "Error",
          text: errorMessage,
          confirmButtonColor: Colors.yellowAcademy,
        });
      }
    } catch (error) {
      console.error("Error in handleForgotPassword:", error.message);
      // Handle any additional errors or logging here
    }
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      maxWidth={"xs"}
      PaperProps={{
        sx: {
          backgroundColor: Colors.bgLight,
          borderRadius: "32px",
          width: "100%",

          minWidth: { xl: "15%", lg: "21%", md: "25%", sm: "35%", xs: "40%" },
        },
      }}
    >
      <Box sx={{ position: "relative" }}>
        <IconButton
          sx={{
            position: "absolute",
            right: 10,
            top: 10,
            color: "white",
            backgroundColor: Colors.bgLight,
          }}
          onClick={handleClose}
        >
          <MdClose />
        </IconButton>
      </Box>
      <DialogTitle sx={{ fontSize: 14 }}>
        Esta seguro que desea cambiar su contraseña?
      </DialogTitle>

      <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
        <VgButton
          title={"Cambiar Contraseña"}
          onPress={handleForgotPassword}
          fontSize={12}
          bgColor={Colors.yellowAcademy}
          bgColorB={Colors.yellowDark}
          width={200}
        />
      </DialogActions>
    </Dialog>
  );
};
