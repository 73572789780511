import {
  MdSignalCellular1Bar,
  MdPhoto,
  MdCastForEducation,
  MdPriorityHigh,
  MdHome,
  MdSupervisedUserCircle,
  MdEditCalendar,
  MdPeople,
  MdAccountBox,
  MdOutlineAccountBox,
  MdSchool,
  MdOutlineSchool,
  MdOutlineHome,
  MdOutlineSchedule,
  MdOutlineCalendarMonth,
} from "react-icons/md";

export const menu = [
  // {
  //   id: "1",
  //   route: "/home",
  //   title: "Inicio",
  //   icon: <MdHome />,
  // },
  {
    id: "1",

    route: "/profile",
    title: "Perfil",
    icon: <MdOutlineAccountBox size={24} />,
  },
  {
    id: "2",

    route: "/home",
    title: "Inicio",
    icon: <MdOutlineHome size={24} />,
  },
  {
    id: "3",
    title: "Academia",
    icon: <MdOutlineSchool size={24} />,
    submenu: [
      {
        route: "/academy",
        title: "Mastermind",
        icon: <MdCastForEducation size={24} />,
      },
      {
        route: "/trading",
        title: "Trading",
        icon: <MdCastForEducation size={24} />,
      },
    ],
  },
  {
    id: "4",
    route: "/schedules",
    title: "Agenda",
    icon: <MdOutlineCalendarMonth size={24} />,
  },
];
