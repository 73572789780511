import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import Colors from "../../utils/Colors";
import VgButton from "../../components/general/VgButton";
import { MdArrowBack } from "react-icons/md";

export const VideoPlayer = ({
  videos,
  selectedVideo,
  setSelectedVideo,
  videoDetails,
  setVideoDetails,
  isActive,
  isGoldPlanUser,
  handleVideoItemClick,
}) => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  useEffect(() => {
    console.log("selectedVideo:", selectedVideo);
    console.log("videoDetails:", videoDetails);
  }, [selectedVideo, videos, videoDetails, currentVideoIndex]);

  const playNextVideo = () => {
    const nextIndex = currentVideoIndex + 1;
    if (nextIndex < videos.length) {
      const nextVideo = videos[nextIndex];
      setSelectedVideo(nextVideo?.videoURL);
      setVideoDetails(nextVideo);
      setCurrentVideoIndex(nextIndex);
    }
  };
  const playPreviousVideo = () => {
    const previousIndex = currentVideoIndex - 1;
    if (previousIndex >= 0) {
      const previousVideo = videos[previousIndex];
      setSelectedVideo(previousVideo?.videoURL);
      setVideoDetails(previousVideo);
      setCurrentVideoIndex(previousIndex);
    }
  };

  useEffect(() => {
    // Update the currentVideoIndex when the selectedVideo changes
    const index = videos.findIndex((video) => video.url === selectedVideo);
    if (index !== -1) {
      setCurrentVideoIndex(index);
    }
  }, [selectedVideo, videos]);
  const currentvideo = "https://vimeo.com/902071269?share=copy";
  return (
    <Grid container justifyContent={"center"} alignItems={"center"} mt={4}>
      <Grid
        item
        md={12}
        container
        justifyContent={"center"}
        flexDirection={"column"}
        alignItems={"center"}
      >
        <Box
          sx={{
            position: "relative",
            display: "flex",
            width: "66%",
            flexDirection: "column",
            py: 2,
            minHeight: "375px",
          }}
        >
          <Typography
            variant="h3"
            mb={1}
            sx={{ width: "66%", textAlign: "flex-start", fontSize: 22 }}
          >
            <IconButton
              onClick={handleVideoItemClick}
              sx={{
                background: `linear-gradient(to left, ${Colors.yellowDark}, ${Colors.yellowAcademy})`,
                mr: 1,
              }}
            >
              <MdArrowBack size={18} color={Colors.bg} />
            </IconButton>
            {videoDetails?.name}
          </Typography>

          <ReactPlayer
            url={selectedVideo}
            // url={currentvideo}
            controls
            playing
            width="100%"
            height="375px" // Adjust the height as needed
          />
        </Box>

        <Box
          sx={{
            width: "66%",
            display: "flex",
            justifyContent: "space-between",
            mt: 2,
          }}
        >
          <VgButton
            onPress={playPreviousVideo}
            shadow
            disabled={currentVideoIndex === 0}
            bgColor={currentVideoIndex === 0 ? Colors.grey2 : Colors.greyLight}
            bgcolorB={currentVideoIndex === 0 ? Colors.grey2 : Colors.greyLight}
            title={"Anterior"}
          />
          <VgButton
            onPress={playNextVideo}
            shadow
            disabled={currentVideoIndex === videos?.length - 1}
            bgColor={
              currentVideoIndex === videos?.length - 1
                ? Colors.greyLight
                : Colors.greyLight
            }
            bgColorB={
              currentVideoIndex === videos?.length - 1
                ? Colors.grey2
                : Colors.greyLight
            }
            title={"Siguiente"}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
