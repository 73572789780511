import { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TopNavbar from "./TopNavbar";
import { sidebarStyles } from "./../../assets/styles/sidebarStyles";
import MenuDropdownButton from "./menu/MenuDropdownButton";
import MenuButton from "./menu/MenuButton";
import { menu } from "./menu/menu";
import {
  Box,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
} from "@mui/material";
import Colors from "../utils/Colors";
import { MdLogout } from "react-icons/md";
import { logoutUser, setIsLoadingAuth } from "../store/actions/auth";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const drawerWidth = 225;
const drawerWidthClosed = 75;

const openedMixin = (theme) => ({
  width: drawerWidth,
  boxSizing: "border-box",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  background: "white",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: drawerWidthClosed,
  [theme.breakpoints.up("sm")]: {
    width: drawerWidthClosed,
  },
});
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",

  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  background: "white",
  width: open ? drawerWidth : drawerWidthClosed,
  transition: theme.transitions.create(["width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),

  "& .MuiDrawer-paper": {
    width: open ? drawerWidth : drawerWidthClosed,
    background: "white",
    transition: theme.transitions.create(["width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}));

export default function SidebarDrawer({
  handleDrawerToggle,
  sidebarOpen,
  setOpen,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [openD, setOpenD] = useState({});

  function handleClick(id) {
    setOpenD((prevState) => ({ ...prevState, [id]: !prevState[id] }));
  }
  const handleLogout = (event) => {
    dispatch(logoutUser());
    sessionStorage.clear();
    window.location.reload(false);
    dispatch(setIsLoadingAuth(true));
    if (location.pathname !== "/home") {
      navigate("/");
    } else {
      // window.location.reload(false);
    }
  };
  return (
    <>
      <TopNavbar
        drawerWidth={sidebarOpen ? drawerWidth : drawerWidthClosed}
        handleDrawerOpen={handleDrawerToggle}
        setOpen={setOpen}
        open={sidebarOpen}
        isMobile={isMobile}
      />

      <Drawer
        variant={
          !isMobile
            ? sidebarOpen
              ? "permanent"
              : "permanent"
            : sidebarOpen
            ? "permanent"
            : "persistent"
        }
        open={sidebarOpen}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerToggle}>
            {!sidebarOpen ? (
              <ChevronRightIcon sx={{ color: Colors.bgAcademy }} />
            ) : (
              <ChevronLeftIcon sx={{ color: Colors.bg }} />
            )}
          </IconButton>
        </DrawerHeader>
        <Box
          mt={2}
          mb={4}
          sx={{
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={handleDrawerToggle}
        >
          <img
            style={{ width: sidebarOpen ? "128px" : "50px", height: "auto" }}
            src={require("../../assets/img/logos/logoColor.png")}
            alt="vanguard"
          />
        </Box>
        <List>
          {menu.map((item) => {
            return (
              <div key={item.title}>
                {item.submenu != null ? (
                  <MenuDropdownButton
                    open={sidebarOpen}
                    openD={openD}
                    handleClick={handleClick}
                    submenu={item.submenu}
                    item={item}
                  />
                ) : (
                  <MenuButton open={sidebarOpen} item={item} />
                )}
              </div>
            );
          })}
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: sidebarOpen ? "initial" : "center",
              px: 2.5,
            }}
            onClick={handleLogout}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: sidebarOpen ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <MdLogout size={24} />
            </ListItemIcon>
            {sidebarOpen && (
              <ListItemText
                primary={"Cerrar Sesiön"}
                sx={{ fontWeight: "bold" }}
              ></ListItemText>
            )}
          </ListItemButton>
        </List>
      </Drawer>
    </>
  );
}
