import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { handleResetPassword, login } from "../../store/actions/auth";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styles } from "../../../assets/styles/styles";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import loginImg from "../../../assets/img/login.jpg";
import logo from "../../../assets/img/logos/logoColor.png";
import { Container, FormControl, useTheme } from "@mui/material";
import loginBG from "../../../assets/img/loginBG.png";
import Colors from "../../utils/Colors";
import { ForgotPassword } from "../../components/auth/ForgotPassword";

const loginCardStyle = {
  backgroundColor: `${styles.white}`,
  boxShadow: 0,
  borderRadius: 1,
  border: "none",
  marginTop: 8,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  maxWidth: 400,
};

const Login = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { errorMsg } = useSelector((state) => state.auth);
  const [loading, setloading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: yup.object({
      email: yup
        .string("Ingresa un correo electrónico")
        .email("Ingresa un correo electrónico válido")
        .required("Email is required"),
      password: yup
        .string("Ingresa una contraseña")
        .min(6, "Mínimo 6 caractéres")
        .required("La contraseña es requerida"),
    }),
    onSubmit: async (values) => {
      try {
        setloading(true);
        dispatch(login(values.email, values.password, setloading));
        // Clear any previous error message on successful login
      } catch (error) {
        // Handle the unsuccessful login here
        console.error("Login failed:", error);
        // Set the error message here
      }
    },
  });
  const navigateTo = (route) => {
    navigate(route);
  };

  const handleLogin = (values) => {
    dispatch(login(values.email, values.password));
  };
  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        background: Colors.bgAcademy,
        minHeight: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container>
        <Grid
          container
          item
          sm={12}
          xs={12}
          justifyContent={"center"}
          alignContent={"center"}
          alignItems={"center"}
          sx={{
            boxShadow: " rgba(0, 0, 0, 0.35) 0px 5px 15px;",
            borderRadius: "18px",
            [theme.breakpoints.down("sm")]: {
              boxShadow: " none",
            },
          }}
        >
          <Grid
            item
            md={6}
            sm={6}
            xs={0}
            order={{ xs: 2, sm: 1, md: 1, lg: 1 }}
            sx={{
              height: "750px",
              backgroundColor: Colors.bgAcademy,
              backgroundImage: `url(${loginImg})`, // Use the imported image
              backgroundSize: "cover",
              borderTopRightRadius: "0px",

              borderTopLeftRadius: "18px",
              borderBottomLeftRadius: "18px",
              [theme.breakpoints.down("md")]: {
                backgroundPositionX: "right",
                backgroundPositionY: "center",
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "auto",
                [theme.breakpoints.down("sm")]: {
                  margin: "1em auto 2em auto",
                  width: "50%",
                  height: "auto",
                },
              }}
            ></Box>
          </Grid>
          <Grid
            item
            sm={6}
            md={6}
            lg={6}
            xs={10}
            container
            order={{ xs: 1, sm: 2, md: 2, lg: 2 }}
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{
              height: "750px",
              backgroundColor: Colors.bgAcademy,
              backgroundImage: `url(${loginBG})`, // Use the imported image
              backgroundSize: "cover",
              borderTopRightRadius: "18px",
              borderTopLeftRadius: "0px",
              borderBottomRightRadius: "18px",
              borderBottomLeftRadius: "0px",

              [theme.breakpoints.down("md")]: {
                backgroundSize: "1000px , 1000px",
                boxShadow: " rgba(0, 0, 0, 0.35) 0px 5px 15px;",
                borderRadius: "18px",
              },
            }}
          >
            <form
              onSubmit={formik.handleSubmit}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "65%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
                mb={2}
              >
                <img
                  src={logo}
                  alt="Home"
                  style={{ width: "60%", height: "auto" }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
                mb={2}
              >
                <Typography
                  variant="h6"
                  sx={{ textAlign: "center", fontWeight: "bold" }}
                >
                  INICIA SESIÓN
                </Typography>
              </Box>
              <FormControl sx={{ mb: 2 }} fullWidth>
                <TextField
                  label="Email"
                  type="email"
                  id="email"
                  variant="outlined"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  label="Password"
                  type="password"
                  id="password"
                  variant="outlined"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                />
              </FormControl>

              {errorMsg && (
                <Typography variant="body1" color="error">
                  {errorMsg}
                </Typography>
              )}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  mt: 2,
                }}
              >
                {/* <FormControlLabel
                  control={
                    <Checkbox
                      id="rememberMe"
                      onChange={formik.handleChange}
                      checked={formik.values.rememberMe}
                    />
                  }
                  label="Recordar Contraseña"
                /> */}
                <Typography
                  variant="body1"
                  sx={{ cursor: "pointer" }}
                  onClick={() => setOpenDialog(true)}
                >
                  Olvidaste la contraseña?
                </Typography>
              </Box>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ mt: 4 }}
              >
                Iniciar Sesión
              </Button>
            </form>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                mt: 3,
              }}
            >
              <Typography variant="body1" sx={{ cursor: "pointer", mb: 1 }}>
                ¿ERES NUEVO?
              </Typography>
              <Typography
                variant="body1"
                sx={{ cursor: "pointer" }}
                onClick={() => navigateTo("/auth/register")}
              >
                REGISTRATE AHORA
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <ForgotPassword handleClose={handleClose} openDialog={openDialog} />
    </Box>
  );
};

export default Login;
